import React from "react";
import {
  faCalendarCheck,
  faChampagneGlasses,
  faCogs,
  faLocationDot,
  faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
} from "@mui/material";
import { Stack } from "@mui/system";
import { NavLink } from "react-router-dom";
import { getRuta } from "../../helpers/Helpers";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import RequestQuoteRoundedIcon from "@mui/icons-material/RequestQuoteRounded";

export const Configurations = ({ drawerIsOpen }) => {
  const [open, setOpen] = React.useState(false);
  let rout = getRuta();
  return (
    <div>
      <ListItemButton onClick={() => setOpen(!open)} style={{ padding: 0 }}>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={faCogs}
            style={{
              fontSize: 22,
            }}
          />
        </ListItemIcon>
        <ListItemText primary="Configuraciones" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <Stack marginTop={1} paddingLeft={drawerIsOpen ? 4 : 0.5} spacing={0.5}>
          <NavLink
            to={`${rout}/locations`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={3} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Ubicaciones</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/tasa-interes`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={3} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faPercent}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Tasa de Interes</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/periodicidad`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={3} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Periodicidad</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/holidays`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <FontAwesomeIcon
                icon={faChampagneGlasses}
                style={{
                  fontSize: 20,
                }}
              />
              <Typography>Días Festivos</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/conceptogastos`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <RequestQuoteRoundedIcon
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Concepto Gastos</Typography>
            </Stack>
          </NavLink>

          <NavLink
            to={`${rout}/tasa-cambio`}
            className={(navData) => (navData.isActive ? "active" : "inactive")}
          >
            <Stack spacing={2} direction="row" alignItems={"center"}>
              <CurrencyExchangeIcon
                style={{
                  fontSize: 22,
                }}
              />
              <Typography>Tasa de Cambio</Typography>
            </Stack>
          </NavLink>
        </Stack>
      </Collapse>
    </div>
  );
};

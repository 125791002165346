import React, { useState, useContext } from "react";
import { TextField, Divider, Container, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import { getToken } from "../../../api/Account";
import moment from "moment/moment";
import { addHolidayAsync } from "../../../api/Holiday";

const AddHoliday = ({ setShowModal, localReload, setLocalReload }) => {
  const [fecha, setFecha] = useState(new Date());
  const [description, setDescription] = useState("");

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const saveChangesAsync = async () => {
    if (!moment(fecha).isValid()) {
      simpleMessage("Seleccione una fecha válida...", "error");
      return;
    }
    if (description.length === 0) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }
    const data = {
      fecha,
      description,
    };

    setIsLoading(true);
    const result = await addHolidayAsync(token, data);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    simpleMessage("Día Festivo Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  return (
    <div>
      <Divider />
      <Container style={{ width: 450 }}>
        <DesktopDatePicker
          closeOnSelect
          label="Fecha Primera Cuota"
          inputFormat="DD/MM/YYYY"
          value={fecha}
          onChange={(newValue) => setFecha(newValue)}
          renderInput={(params) => <TextField fullWidth {...params} />}
        />

        <TextField
          fullWidth
          required
          style={{ marginTop: 20 }}
          onChange={(e) => setDescription(e.target.value.toUpperCase())}
          label={"Descripcion"}
          value={description}
        />

        <Button
          variant="outlined"
          startIcon={
            <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
          }
          fullWidth
          onClick={() => saveChangesAsync()}
          style={{
            marginTop: 30,
            borderRadius: 20,
            color: "#00a152",
            borderColor: "#00a152",
          }}
          size="large"
        >
          Agregar Dia Feriado
        </Button>
      </Container>
    </div>
  );
};

export default AddHoliday;

import React, { createContext, useState } from 'react';
export const DataContext = createContext();

//Si es Igual a produccion es de dimas
// lo pongo !== de prod para subirlo a mi demo
let idDev = process.env.NODE_ENV === 'production' ? true : false;

export const DataProvider = ({ children }) => {
	const [isDarkMode, setIsDarkMode] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLogged, setIsLogged] = useState(null);
	const [version, setVersion] = useState('v-5.5.4');

	const [title, setTitle] = useState(idDev ? 'CreditosAM' : 'CrediApp');
	const [ruc] = useState(idDev ? '1632710830000H' : '0810506870003P');

	const [docTitle, setDocTitle] = useState(idDev ? 'CreditosAM' : 'CrediApp');
	const [slogan, setSlogan] = useState(idDev ? '' : '¡Desarrollado tu Futuro!');

	const [reload, setReload] = useState(false);
	const [access, setAccess] = useState([]);
	const [isUnautorized, setIsUnautorized] = useState(false);
	const [isServerOff, setIsServerOff] = useState(false);

	const [icon, setIcon] = useState(
		require(idDev ? '../components/media/Icon.png' : '../components/media/logo.png')
	);

	const [favicon] = useState(
		require(idDev ? '../components/media/faviconProd.ico' : '../components/media/faviconCA.ico')
	);

	const [showPrintBillModal, setShowPrintBillModal] = useState(false);
	const [dataAbono, setDataAbono] = useState([]);

	return (
		<DataContext.Provider
			value={{
				isDarkMode,
				setIsDarkMode,
				isLoading,
				setIsLoading,
				isLogged,
				setIsLogged,
				title,
				setTitle,
				reload,
				setReload,
				access,
				setAccess,
				isUnautorized,
				setIsUnautorized,
				slogan,
				setSlogan,
				docTitle,
				setDocTitle,
				icon,
				setIcon,
				version,
				setVersion,
				favicon,
				isServerOff,
				setIsServerOff,
				showPrintBillModal,
				setShowPrintBillModal,
				dataAbono,
				setDataAbono,
				ruc
			}}
		>
			{children}
		</DataContext.Provider>
	);
};

import React, { useState, useEffect, useContext } from "react";

import { simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { getMoraByGestorAsync } from "../../../api/Dashboard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const MoraByGestor = () => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  const { setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getMoraByGestorAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);

      let names = [];
      let val = [];
      result.data.result.forEach(function (i) {
        names.push(`${i.gestor.split(" ")[0]} ${i.gestor.split(" ")[1][0]}`);
        val.push(i.montoCobrado);
      });
      setLabels(names);
      setData(val);
    })();
  }, []);

  const options = {
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("es-NI", {
                style: "currency",
                currency: "NIO",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },

    scales: {
      y: {
        ticks: {
          callback: (value, index, values) => {
            return new Intl.NumberFormat("es-NI", {
              style: "currency",
              currency: "NIO",
            }).format(value);
          },
        },
        beginAtZero: true,
      },
    },
  };

  const dataSet = {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div>
      <Bar data={dataSet} options={options} />
    </div>
  );
};

export default MoraByGestor;

import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Divider,
  Container,
  Button,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";
import { simpleMessage } from "../../../helpers/Helpers";
import {
  addAjusteCajaAsync,
  addCajaInAsync,
  addCajaOutAsync,
} from "../../../api/Cash";
import { getConceptoGastosAsync } from "../../../api/Contabilidad";

const AddCajaMovment = ({ setShowModal, localReload, setLocalReload }) => {
  const [monto, setMonto] = useState("");
  const [montoSalida, setMontoSalida] = useState("");
  const [description, setDescription] = useState("");
  const [tipoGasto, setTipogasto] = useState(1);
  const [cuentaOrigen, setCuentaOrigen] = useState("");
  const [conceptoList, setConceptoList] = useState([]);
  const [selectedConcepto, setSelectedConcepto] = useState("");

  const [tipoMov, setTipoMov] = useState(1);

  const { setIsLoading, setIsUnautorized } = useContext(DataContext);

  const token = getToken();

  const montoMov = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMonto(value);
      return;
    }
  };

  const montoMovSalida = (value) => {
    if (/^\d*\.?\d*$/.test(value.toString()) || value === "") {
      setMontoSalida(value);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      await getData(tipoGasto);
    })();
  }, []);

  const handleChangeSelect = async (id) => {
    setTipogasto(id);
    await getData(id);
  };

  const saveChangesAsync = async () => {
    if (monto.length === 0) {
      simpleMessage("Agrege un monto válido...", "error");
      return;
    }

    if (description.length === 0 && tipoMov !==2 ) {
      simpleMessage("Ingrese una descripción...", "error");
      return;
    }

    if (tipoMov === 1) {
      const data = {
        monto,
        description,
        isBanco: cuentaOrigen === 0 ? 1 : 2,
      };

      setIsLoading(true);
      const result = await addCajaInAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
    } else if (tipoMov === 2) {
      const data = {
        monto,
        conceptoId: selectedConcepto,
      };

      setIsLoading(true);
      const result = await addCajaOutAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
    } else {
      const data = {
        montoEntrada: monto.length === 0 ? 0 : monto,
        montoSalida: montoSalida.length === 0 ? 0 : montoSalida,
        description,
      };

      setIsLoading(true);
      const result = await addAjusteCajaAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
    }

    setIsLoading(false);
    simpleMessage("Movimiento Agregado...!", "success");
    setLocalReload(!localReload);
    setShowModal(false);
  };

  const getData = async (id) => {
    setIsLoading(true);
    const result = await getConceptoGastosAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }
    setConceptoList(result.data.result);
    setIsLoading(false);
  };

  return (
    <div>
      <hr />
      <Container style={{ width: 450 }}>
        <Stack spacing={2}>
          <FormControl style={{ textAlign: "left" }}>
            <InputLabel id="demo-simple-select-standard-label">
              Tipo de Movimiento
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => {
                setTipoMov(e.target.value);
              }}
              value={tipoMov}
              label="Tipo de Movimiento"
            >
              <MenuItem value={1}>ENTRADA DE EFECTIVO</MenuItem>
              <MenuItem value={2}>SALIDA DE EFECTIVO</MenuItem>
              <MenuItem value={3}>AJUSTE DE CAJA</MenuItem>
            </Select>
          </FormControl>
          {tipoMov === 1 ? (
            <FormControl style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Cuenta Origen
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setCuentaOrigen(e.target.value)}
                value={cuentaOrigen}
                label="Cuenta Origen"
              >
                <MenuItem value={0}>Bancos</MenuItem>
                <MenuItem value={1}>Otros Fondos</MenuItem>
              </Select>
            </FormControl>
          ) : tipoMov === 2 ? (
            <FormControl style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Tipo Gasto
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => handleChangeSelect(e.target.value)}
                value={tipoGasto}
                label="Tipo Gasto"
              >
                <MenuItem value={1}>Gastos Administrativos</MenuItem>
                <MenuItem value={2}>Gastos Operativos</MenuItem>
                <MenuItem value={3}>A Otros Gastos</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <></>
          )}

          {tipoMov === 2 ? (
            <FormControl style={{ textAlign: "left", marginTop: 10 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Conceptos
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setSelectedConcepto(e.target.value)}
                value={selectedConcepto}
                label="Conceptos"
              >
                {conceptoList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              required
              onChange={(e) => setDescription(e.target.value.toUpperCase())}
              label={"Concepto"}
              value={description}
            />
          )}

          <TextField
            fullWidth
            required
            onChange={(e) => montoMov(e.target.value)}
            label={tipoMov === 3 ? "Monto Entrada" : "Monto"}
            value={monto}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">C$</InputAdornment>
              ),
            }}
          />

          {tipoMov === 3 ? (
            <TextField
              fullWidth
              required
              onChange={(e) => montoMovSalida(e.target.value)}
              label={"Monto Salida"}
              value={montoSalida}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">C$</InputAdornment>
                ),
              }}
            />
          ) : (
            <></>
          )}

          <Button
            fullWidth
            variant="outlined"
            style={{
              padding: 15,
              color: "#2979ff",
              borderColor: "#2979ff",
            }}
            startIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={() => saveChangesAsync()}
          >
            {tipoMov === 1
              ? "Agregar Entrada de efectivo"
              : tipoMov === 2
              ? "Agregar Salida de efectivo"
              : "Ajuste de Caja"}
          </Button>
        </Stack>
      </Container>
    </div>
  );
};

export default AddCajaMovment;

import React, { useContext, useEffect, useState } from "react";

import { Typography, Stack } from "@mui/material";

import { DataContext } from "../../../../context/DataContext";
import { conv2Letras } from "../../../../helpers/n2Letras";

import { getToken } from "../../../../api/Account";
import { simpleMessage } from "../../../../helpers/Helpers";
import { getClientFullAddressAsync } from "../../../../api/Clients";
import { isEmpty } from "lodash";

export const PrintPagare = React.forwardRef((props, ref) => {
  const [plazoMes, setPlazoMes] = useState(0);
  const [address, setAddress] = useState("");
  const [mes, setMes] = useState("");
  const { title, slogan, icon, setIsLoading, setIsUnautorized, docTitle } =
    useContext(DataContext);
  const {
    id,
    client,
    montoPagar,
    montoEntregado,
    plazo,
    periocidad,
    cuotaPagar,
    fechaEntrega,
    isEntregado,
  } = props.dataCredit;

  const getPageMargins = () => {
    return `body {
          color:black;
        }`;
  };

  const token = getToken();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getClientFullAddressAsync(token, client.id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setAddress(result.data.result);
      setIsLoading(false);
    })();
    setPlazoMes(
      (periocidad.cuotaDias * plazo.cuotas) / periocidad.cantidadDias
    );
    let fecha = isEntregado ? new Date(fechaEntrega) : new Date();

    const monthNameLong = fecha.toLocaleString("es-NI", { month: "long" });
    setMes(monthNameLong);
  }, []);

  return isEmpty(props.dataCredit) ? (
    <></>
  ) : (
    <div ref={ref}>
      <style>{getPageMargins()}</style>
      <>
        <Stack direction="row" justifyContent="space-between">
          <img src={icon} style={{ width: 70, height: 70 }} />
          <Stack>
            <Typography variant="h5" color="#212121" textAlign={"center"}>
              {title}
            </Typography>
            <Typography color="#212121" textAlign={"center"}>
              {slogan}
            </Typography>
            <Typography variant="h6" color="#212121" textAlign={"center"}>
              Pagaré
            </Typography>
          </Stack>
          <img src={icon} style={{ width: 70, height: 70 }} />
        </Stack>

        <Stack direction="row" marginTop={5} marginBottom={5} spacing={1}>
          <Typography color="#212121" fontWeight={"bold"} variant="h5">
            PAGARE N°:
          </Typography>
          <Typography color="#212121" fontWeight={"bold"} variant="h5">
            {id}
          </Typography>
        </Stack>

        <Typography color="#212121" variant="body1" textAlign="justify">
          {`Yo ${
            props.clientName
          }, mayor de edad, con numero de identificación: ${
            isEmpty(client) ? "" : client.identification
          }, como aparece al pie de mi firma, actuando en nombre propio, por medio del presente, escrito manifiesto, lo siguiente:`}
        </Typography>

        <Stack direction={"row"} spacing={2}>
          <Typography color="#212121" variant="body1" fontWeight={"bold"}>
            PRIMERO:
          </Typography>
          <Typography color="#212121" variant="body1" textAlign="justify">
            {`Que debo y pagaré, incondicional y solidariamente a la orden de ${docTitle} o a la persona natural o jurídica a quien el mencionado acreedor seda o endose sus derechos sobre este pagaré, la suma cierta de: ${new Intl.NumberFormat(
              "es-NI",
              {
                style: "currency",
                currency: "NIO",
              }
            ).format(montoEntregado)} CORDOBAS, C$( ${conv2Letras(
              montoEntregado
            )}) moneda legal nicaragüense`}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Typography color="#212121" variant="body1" fontWeight={"bold"}>
            SEGUNDO:
          </Typography>
          <Typography color="#212121" variant="body1" textAlign="justify">
            {`Que el pago total de la mencionada sera de: ${new Intl.NumberFormat(
              "es-NI",
              {
                style: "currency",
                currency: "NIO",
              }
            ).format(
              montoPagar
            )} a pagarse en un plazo de ${plazoMes} meses, En cuotas de ${new Intl.NumberFormat(
              "es-NI",
              {
                style: "currency",
                currency: "NIO",
              }
            ).format(cuotaPagar)}, ${
              isEmpty(periocidad) ? "" : periocidad.description
            } que seran efectivas en casa de vivienda del cliente en la siguiente direccion ubicada en la ciudad de: ${address}`}
          </Typography>
        </Stack>

        <Stack direction={"row"} spacing={2}>
          <Typography color="#212121" variant="body1" fontWeight={"bold"}>
            TERCERO:
          </Typography>
          <Typography color="#212121" variant="body1" textAlign="justify">
            {`Que en caso de mora pagaré a ${docTitle} o a la persona natural o jurídica a quien el mencionado acreedor seda o endose sus derechos, intereses de mora a la tasa mas alta permitida por la ley, desde el día siguiente a la fecha de exigibilidad del presente pagaré, y hasta cuando su pago total se efectue.`}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Typography color="#212121" variant="body1" fontWeight={"bold"}>
            CUARTO:
          </Typography>
          <Typography color="#212121" variant="body1" textAlign="justify">
            {`Expresamente declaro excusando el proceso del presente pagaré y los requerimientos judiciales o extrajudiciales para la constitucion en mora.`}
          </Typography>
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <Typography color="#212121" variant="body1" fontWeight={"bold"}>
            QUINTO:
          </Typography>
          <Typography color="#212121" variant="body1" textAlign="justify">
            {`En caso de que haya lugar al recaudado judicial o extrajudicial de la obligacion contenida en el presente título valor sera a mi cargo los costos judiciales y/a los honorarios que se causen  por tal razón,`}
          </Typography>
        </Stack>
        {isEntregado ? (
          <Stack direction={"row"} spacing={2}>
            <Typography color="#212121" variant="body1" textAlign="justify">
              {`En constancia de lo anterior, se suscribe en la ciudad de Chinandega, a los ${new Date(
                fechaEntrega
              ).getDate()} días del mes de ${mes} del año  ${new Date(
                fechaEntrega
              ).getFullYear()}`}
            </Typography>
          </Stack>
        ) : (
          <Stack direction={"row"} spacing={2}>
            <Typography color="#212121" variant="body1" textAlign="justify">
              {`En constancia de lo anterior, se suscribe en la ciudad de Chinandega, a los ${new Date().getDate()} días del mes de ${mes} del año  ${new Date().getFullYear()}`}
            </Typography>
          </Stack>
        )}

        <Stack
          direction={"row"}
          spacing={2}
          marginTop={15}
          justifyContent="space-around"
        >
          <Stack spacing={2} justifyContent="center">
            <span>----------------------------</span>

            <Typography textAlign={"center"} color="#212121" variant="h6">
              DEUDOR
            </Typography>
          </Stack>
          <Stack spacing={2} justifyContent="center">
            <span>----------------------------</span>
            <Typography textAlign={"center"} color="#212121" variant="h6">
              {title}
            </Typography>
          </Stack>
        </Stack>
      </>
    </div>
  );
});

export default PrintPagare;

import React from 'react';

import { Route, Routes } from 'react-router-dom';
import AccountContainer from '../pages/account/AccountContainer';
import HomeContainer from '../pages/home/HomeContainer';
import './estilos/estilos.css';

import MainLocations from '../pages/settings/locations/MainLocations';
import Municipalities from '../pages/settings/locations/Municipalities';
import { Communities } from '../pages/settings/locations/Communities';
import MainInteresCorriente from '../pages/settings/interesCorriente/MainInteresCorriente';
import PeriodicidadList from '../pages/settings/Periodicidad/PeriodicidadList';
import TasaCambioList from '../pages/settings/tasaCambio/TasaCambioList';
import ClientList from '../pages/clients/ClientList';
import AddClient from '../pages/clients/AddClient';
import { DetalleClientContainer } from '../pages/clients/detalleCliente/DetalleClientContainer';
import CreditLIst from '../pages/credits/CreditLIst';
import AddCredit from '../pages/credits/AddCredit';
import CreditDetailsContainer from '../pages/credits/creditDetails/CreditDetailsContainer';
import Userlist from '../pages/security/users/Userlist';
import RolList from '../pages/security/rols/RolList';
import AddUser from '../pages/security/users/AddUser';
import EditUser from '../pages/security/users/EditUser';
import AddRol from '../pages/security/rols/AddRol';
import EditRol from '../pages/security/rols/EditRol';
import HolidayList from '../pages/settings/holidays/HolidayList';
import Reports from '../pages/reports/Reports';

import ClientesGestor from '../pages/reports/reportesCartera/ClientesGestor';
import CreditsByGestor from '../pages/reports/reportesCartera/CreditsByGestor';
import CobroDiario from '../pages/reports/reportesCartera/CobroDiario';
import Recuperacion from '../pages/reports/reportesCartera/Recuperacion';
import Desembolsos from '../pages/reports/reportesCartera/Desembolsos';
import SaldosCartera from '../pages/reports/reportesCartera/SaldosCartera';
import DefeatedCredits from '../pages/reports/reportesCartera/DefeatedCredits';
import AccountStatus from '../pages/reports/reportesCartera/AccountStatus';
import Caja from '../pages/admon/caja/Caja';
import Gastos from '../pages/admon/Gastos';
import CierreCaja from '../pages/reports/reportesCartera/CierreCaja';
import CierreCajaDetallado from '../pages/reports/reportesCartera/CierreCajaDetallado';
import EstadoResultado from '../pages/reports/reportesCartera/EstadoResultado';
import ConceptoGastoList from '../pages/settings/gastos/ConceptoGastoList';
import ServerRollist from '../pages/security/server/ServerRollist';
import CreditsCancelled from '../pages/reports/reportesCartera/CreditsCancelled';
import IngresoFinanciero from '../pages/reports/reportesCartera/IngresoFinanciero';
import VencimientosProyectados from '../pages/reports/reportesCartera/VencimientosProyectados';
import { getRuta } from '../helpers/Helpers';
import CashregisterList from '../pages/admon/caja/cashRegister/CashregisterList';
import CashregisterAdd from '../pages/admon/caja/cashRegister/cashRegisterComponents/CashregisterAdd';
import CashRegisterDetail from '../pages/admon/caja/cashRegister/cashRegisterComponents/CashRegisterDetail';

const Rutas = () => {
	let rout = getRuta();
	return (
		<Routes>
			<Route path={`${rout}/`} element={<HomeContainer />} />
			{/* Rulas Clientes */}
			<Route path={`${rout}/clients`} element={<ClientList />} />
			<Route path={`${rout}/addclient`} element={<AddClient />} />
			<Route path={`${rout}/client/:id`} element={<DetalleClientContainer />} />

			{/* Rulas Creditos */}
			<Route path={`${rout}/credits`} element={<CreditLIst />} />
			<Route path={`${rout}/credit/add/:id`} element={<AddCredit />} />
			<Route path={`${rout}/credit/:id`} element={<CreditDetailsContainer />} />

			{/* Rulas Admon */}
			<Route path={`${rout}/cashregister`} element={<CashregisterList />} />
			<Route path={`${rout}/cashregisteradd`} element={<CashregisterAdd />} />
			<Route path={`${rout}/arqueocaja/:id`} element={<CashRegisterDetail />} />

			<Route path={`${rout}/caja`} element={<Caja />} />
			{/* <Route path={`${rout}/caja-chica`} element={<Admon />} /> */}
			<Route path={`${rout}/gastos`} element={<Gastos />} />

			{/* Rulas Reportes */}
			<Route path={`${rout}/reports`} element={<Reports />} />
			<Route
				path={`${rout}/r-clientsgestor/:selectedStatus/:selectedSortBy`}
				element={<ClientesGestor />}
			/>
			<Route
				path={`${rout}/r-creditsbyGestor/:selectedGestor/:selectedSortBy`}
				element={<CreditsByGestor />}
			/>
			<Route
				path={`${rout}/r-cobrodiario/:selectedGestor/:selectedFecha`}
				element={<CobroDiario />}
			/>
			<Route
				path={`${rout}/r-recuperacion/:selectedGestor/:fechaDesde/:fechaHasta`}
				element={<Recuperacion />}
			/>
			<Route
				path={`${rout}/r-ingresofinanciero/:selectedUser/:fechaDesde/:fechaHasta/:moneda`}
				element={<IngresoFinanciero />}
			/>
			<Route
				path={`${rout}/r-desembolsos/:selectedGestor/:fechaDesde/:fechaHasta`}
				element={<Desembolsos />}
			/>
			<Route
				path={`${rout}/r-saldoscartera/:selectedGestor/:selectedSortBy`}
				element={<SaldosCartera />}
			/>
			<Route
				path={`${rout}/r-defeatedcredits/:selectedGestor/:selectedSortBy`}
				element={<DefeatedCredits />}
			/>
			<Route
				path={`${rout}/r-proyecteddefeatedcredits/:selectedGestor/:fechaDesde/:fechaHasta/:selectedSortBy`}
				element={<VencimientosProyectados />}
			/>
			<Route path={`${rout}/r-accountstatus/:selectedCredit`} element={<AccountStatus />} />
			<Route path={`${rout}/r-cierrecajad/:fecha`} element={<CierreCajaDetallado />} />
			<Route path={`${rout}/r-cierrecaja/:fecha`} element={<CierreCaja />} />
			<Route
				path={`${rout}/r-estadoresultado/:fechaDesde/:fechaHasta`}
				element={<EstadoResultado />}
			/>

			<Route
				path={`${rout}/r-creditscancelled/:selectedGestor/:fechaDesde/:fechaHasta`}
				element={<CreditsCancelled />}
			/>

			{/* Rulas Security-users */}
			<Route path={`${rout}/users`} element={<Userlist />} />
			<Route path={`${rout}/adduser`} element={<AddUser />} />
			<Route path={`${rout}/user/:id`} element={<EditUser />} />

			{/* Rulas Security-rols */}
			<Route path={`${rout}/rols`} element={<RolList />} />
			<Route path={`${rout}/addrol`} element={<AddRol />} />
			<Route path={`${rout}/rol/:id`} element={<EditRol />} />

			{/* Rulas Security-server */}
			<Route path={`${rout}/server`} element={<ServerRollist />} />
			{/* <Route path={`${rout}/addrol`} element={<AddRol />} />
              <Route path={`${rout}/rol/:id`} element={<EditRol />} /> */}

			<Route path={`${rout}/account`} element={<AccountContainer />} />

			{/* Rulas Ubicaciones */}
			<Route path={`${rout}/locations`} element={<MainLocations />} />
			<Route path={`${rout}/department/:id`} element={<Municipalities />} />
			<Route path={`${rout}/locations/municipalities/:id`} element={<Communities />} />
			<Route path={`${rout}/locations/municipalities/:id`} element={<Communities />} />

			<Route path={`${rout}/tasa-interes`} element={<MainInteresCorriente />} />
			<Route path={`${rout}/periodicidad`} element={<PeriodicidadList />} />
			<Route path={`${rout}/holidays`} element={<HolidayList />} />
			<Route path={`${rout}/conceptogastos`} element={<ConceptoGastoList />} />
			<Route path={`${rout}/tasa-cambio`} element={<TasaCambioList />} />
		</Routes>
	);
};

export default Rutas;

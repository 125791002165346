import React, { useContext } from 'react';
import { styled, useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Typography, Divider, IconButton } from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Loading from './Loading';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { getRuta } from '../helpers/Helpers';
import { DataContext } from '../context/DataContext';
import NavBarElements from './NavBarElements';
import { Stack } from '@mui/system';

import NavLinks from './links/NavLinks';

import './estilos/estilos.css';
import { isEmpty } from 'lodash';

import Unautorized from './Unautorized';
import { getUser } from '../api/Account';

import Rutas from './Rutas';

const drawerWidth = 250;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(6)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(6)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

export default function MiniDrawer() {
	const { isDarkMode, access, docTitle, version, setIsLogged } = useContext(DataContext);
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const user = getUser();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	let rout = getRuta();

	const tema = createTheme({
		palette: {
			mode: isDarkMode ? 'dark' : 'light',
			background: {
				default: isDarkMode ? '#0A1929' : '#fff'
			}
		},
		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 20
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: { borderRadius: 30 }
				}
			},
			MuiPaper: {
				defaultProps: {
					elevation: 20
				},
				styleOverrides: {
					root: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff',
						borderRadius: 30
					}
				}
			},
			MuiAppBar: {
				styleOverrides: {
					root: {
						backgroundColor: isDarkMode ? 'rgba(0, 20, 50, 0.8)' : '#0A1929C3',
						backdropFilter: 'blur(3px)',
						borderRadius: 0
					}
				}
			},
			MuiDrawer: {
				styleOverrides: {
					paper: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff',
						borderRadius: 0
					}
				}
			},
			MuiMenu: {
				styleOverrides: {
					list: {
						backgroundColor: isDarkMode ? '#0A1929' : '#fff'
					}
				}
			}
		}
	});

	if (isEmpty(access)) {
		return <Loading />;
	}

	if (user === null) {
		setIsLogged(false);
		return;
	}

	return (
		<>
			<ThemeProvider theme={tema}>
				<Box sx={{ display: 'flex' }}>
					<CssBaseline />
					<AppBar position="fixed" open={open}>
						<Toolbar>
							<IconButton
								aria-label="open drawer"
								onClick={handleDrawerOpen}
								edge="start"
								sx={{
									marginRight: 5,
									...(open && { display: 'none' })
								}}
							>
								<ChevronRightIcon />
							</IconButton>
							<NavBarElements />
						</Toolbar>
					</AppBar>

					<Drawer variant="permanent" open={open}>
						<DrawerHeader>
							<Stack marginRight={5}>
								<Typography fontSize={18} textAlign={'center'}>
									{docTitle}
								</Typography>
								<Typography fontSize={15} textAlign={'center'}>
									{version}
								</Typography>
							</Stack>

							<IconButton onClick={handleDrawerClose}>
								{theme.direction === 'rtl' ? (
									<ChevronRightIcon />
								) : (
									<ChevronLeftIcon />
								)}
							</IconButton>
						</DrawerHeader>
						<Divider />
						<NavLinks drawerIsOpen={open} />
					</Drawer>

					<Box
						component="main"
						sx={{
							flexGrow: 1,
							p: 3,
							bgcolor: 'background.default',
							height: '100vh',
							overflow: 'auto'
						}}
					>
						<DrawerHeader />
						<Rutas />
					</Box>
				</Box>
				<Loading />
			</ThemeProvider>
			<Unautorized />
		</>
	);
}

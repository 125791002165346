import React, { useContext } from 'react';

import { Typography, Stack } from '@mui/material';
import moment from 'moment';
import { DataContext } from '../../../../context/DataContext';

const PrintBill = React.forwardRef((props, ref) => {
	const { title, slogan, icon, dataAbono, ruc } = useContext(DataContext);
	const {
		id,
		client,
		concepto,
		cuotaDia,
		diasMora,
		fechaPago,
		montoAtrasado,
		montoCancelacion,
		nuevoSaldo,
		numeroAbono,
		realizadoPor,
		phone,
		totalAbonado,
		quote
	} = dataAbono;

	const getPageMargins = () => {
		return `body {
          color:black;
        }`;
	};

	const { isDolar } = props;

	return (
		<div ref={ref}>
			<style>{getPageMargins()}</style>
			<>
				<Stack>
					<Typography variant="h6" textAlign={'center'}>
						{title}
					</Typography>
					<Typography textAlign={'center'}>{slogan}</Typography>
				</Stack>

				<Stack>
					<Typography textAlign={'center'}>{`RUC - ${ruc}`}</Typography>
					<Typography textAlign={'center'}>{`Telefono - ${phone}`}</Typography>
					<Typography textAlign={'center'}>{`SUCURSAL - SOMOTILLO`}</Typography>
					<Typography textAlign={'center'}>
						{`Cobro del Dia # - ${numeroAbono.toString()}`}
					</Typography>

					<hr />
				</Stack>

				<Stack>
					<Typography textAlign={'center'}>{`Transaccion - ${quote.id}`}</Typography>
					<Typography fontSize={12} textAlign={'center'}>{`Fecha/hora - ${moment(
						fechaPago
					).format('D/MM/yyy hh:mm A')}`}</Typography>
					<Typography textAlign={'center'}>{`CLIENTE: ${client}`}</Typography>

					<hr />
				</Stack>

				<Stack>
					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Cuota dia`}</Typography>

						<Typography textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(cuotaDia)}`}
						</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Monto Atrasado`}</Typography>

						<Typography textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoAtrasado)}`}
						</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Dias Mora`}</Typography>

						<Typography textAlign="right">{diasMora.toString()}</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Total Pagar`}</Typography>

						<Typography textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoAtrasado + cuotaDia)}`}
						</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Monto Cancelacion`}</Typography>

						<Typography textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoCancelacion)}`}
						</Typography>
					</Stack>

					<hr />
				</Stack>

				<Stack>
					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Total Abonado`}</Typography>

						<Typography textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(totalAbonado)}`}
						</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Concepto`}</Typography>

						<Typography textAlign={'right'}>{concepto}</Typography>
					</Stack>

					<Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
						<Typography textAlign={'left'}>{`Nuevo Saldo`}</Typography>

						<Typography textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(nuevoSaldo)}`}
						</Typography>
					</Stack>

					<hr />
					<Typography textAlign={'center'}>{realizadoPor}</Typography>
				</Stack>
			</>
		</div>
	);
});

export default PrintBill;

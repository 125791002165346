import React from "react";
import { Paper, Grid } from "@mui/material";

import ClientCount from "./homeInfoComponents/ClientCount";
import ActiveCredits from "./homeInfoComponents/ActiveCredits";
import VecidoCredits from "./homeInfoComponents/VecidoCredits";
import RecuperacionDiaria from "./homeInfoComponents/RecuperacionDiaria";
import PendieteCredits from "./homeInfoComponents/PendieteCredits";
import MoraTotal from "./homeInfoComponents/MoraTotal";

const HomeInfo = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper>
            <ClientCount />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={10}>
            <ActiveCredits />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={10}>
            <VecidoCredits />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={10}>
            <PendieteCredits />
          </Paper>
        </Grid>

        <Grid item sm={12} md={6}>
          <Paper elevation={10}>
            <RecuperacionDiaria />
          </Paper>
        </Grid>

        <Grid item sm={12} md={6}>
          <Paper elevation={10}>
            <MoraTotal />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeInfo;

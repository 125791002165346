import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Select,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronCircleLeft,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { getRuta, isAccess, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { getRolListAsync } from "../../../api/Rols";
import {
  getJefesListAsync,
  getUserByIdAsync,
  updateUserAsync,
} from "../../../api/Users";

const EditUser = () => {
  const token = getToken();
  const { id } = useParams();
  let ruta = getRuta();
  let navigate = useNavigate();
  const { setIsLoading, setIsLogged, setIsUnautorized, access } =
    useContext(DataContext);

  const [rolesList, setRolesList] = useState([]);
  const [jefeList, setJefeList] = useState([]);

  const [selectedRol, setSelectedRol] = useState("");
  const [selectedJefe, setSelectedJefe] = useState("");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondLastName, setSecondLastName] = useState("");

  const [userName, setUserName] = useState("");

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const [isEdit, setIsEdit] = useState(false);

  const getRolList = async () => {
    setIsLoading(true);
    const result = await getRolListAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setRolesList(result.data.result);

    setIsLoading(false);
  };

  const getJefes = async (id) => {
    setSelectedJefe("");

    const result = await getJefesListAsync(token, id);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setJefeList(result.data.result);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      getRolList();

      const result = await getUserByIdAsync(token, id);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);

      setFirstName(result.data.result.firstName);
      setSecondName(result.data.result.secondName);
      setLastName(result.data.result.lastName);
      setSecondLastName(result.data.result.secondLastName);
      setPhone(result.data.result.phone);

      setUserName(result.data.result.userName);

      setSelectedRol(result.data.result.userRol.id);

      getJefes(result.data.result.userRol.id);

      setSelectedJefe(result.data.result.supervisorId);

      setAddress(result.data.result.address);
      setIsLoading(false);
    })();
  }, []);

  const handleChangeRol = async (event) => {
    let selected = event.target.value;
    setSelectedJefe("");
    setSelectedRol(selected);
    const result = await getJefesListAsync(token, selected);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setJefeList(result.data.result);
    setIsLoading(false);
  };

  const saveChanges = async () => {
    if (validate()) {
      const datos = {
        id,
        firstName,
        secondName,
        lastName,
        secondLastName,
        userName,
        phone,
        address,
        supervisorId: selectedJefe === "" ? selectedRol : selectedJefe,
        idRole: selectedRol,
      };

      setIsLoading(true);
      const result = await updateUserAsync(token, datos);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }

        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      simpleMessage("Usuario actualizado...!", "success");
      setIsEdit(false);
    }
  };

  const validate = () => {
    let isValid = true;
    if (isEmpty(firstName)) {
      simpleMessage("Debe ingresar un nombre", "error");
      return (isValid = false);
    }

    if (isEmpty(lastName)) {
      simpleMessage("Debe ingresar un apellido", "error");
      return (isValid = false);
    }
    if (selectedJefe === "") {
      if (selectedRol !== 2) {
        simpleMessage("Debe seleccionar un puesto de trabajo", "error");
        return (isValid = false);
      }
    }

    if (isEmpty(userName)) {
      simpleMessage("Debe ingresar usuario de acceso", "error");
      return (isValid = false);
    }

    if (selectedRol === "") {
      simpleMessage("Debe seleccionar un rol de usuario", "error");
      return (isValid = false);
    }
    if (isEmpty(phone)) {
      simpleMessage("Debe ingresar un numero telefonico", "error");
      return (isValid = false);
    }
    return isValid;
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={
              isAccess(access, "SECURITY UPDATE") ? "space-between" : ""
            }
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/users`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Datos del Usuario
            </Typography>

            {isAccess(access, "SECURITY UPDATE") ? (
              <Button
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
                style={{
                  color: "#ffc400",
                  borderColor: "#ffc400",
                }}
                variant="outlined"
              >
                <FontAwesomeIcon
                  style={{ marginRight: 10, fontSize: 20 }}
                  icon={faPenToSquare}
                />
                Editar
              </Button>
            ) : (
              <></>
            )}
          </Stack>

          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                  value={firstName}
                  label={"Primer Nombre"}
                  disabled={!isEdit}
                />

                <TextField
                  fullWidth
                  onChange={(e) => setSecondName(e.target.value.toUpperCase())}
                  value={secondName}
                  label={"Segundo Nombre"}
                  disabled={!isEdit}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setLastName(e.target.value.toUpperCase())}
                  value={lastName}
                  label={"Primer apellido"}
                  disabled={!isEdit}
                />
                <TextField
                  fullWidth
                  onChange={(e) =>
                    setSecondLastName(e.target.value.toUpperCase())
                  }
                  value={secondLastName}
                  label={"Segundo Apellido"}
                  disabled={!isEdit}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  required
                  onChange={(e) => setPhone(e.target.value.toUpperCase())}
                  value={phone}
                  label={"Telefono"}
                  disabled={!isEdit}
                />

                <TextField
                  required
                  fullWidth
                  onChange={(e) => setUserName(e.target.value.toLowerCase())}
                  value={userName}
                  label={"Usuario"}
                  disabled={!isEdit}
                />

                <FormControl fullWidth required disabled={!isEdit}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Seleccione un rol
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedRol}
                    onChange={handleChangeRol}
                    label="Rol de usuario"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un rol de usuario</em>
                    </MenuItem>
                    {rolesList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.rolName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth required disabled={!isEdit}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Jefe Inmediato
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedJefe}
                    onChange={(e) => setSelectedJefe(e.target.value)}
                    label="Jefe Inmediato"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un Jefe Inmediato</em>
                    </MenuItem>
                    {jefeList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.fullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                onChange={(e) => setAddress(e.target.value.toUpperCase())}
                value={address}
                label={"Direccion"}
                disabled={!isEdit}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                disabled={!isEdit}
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => saveChanges()}
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Guardar Cambios
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default EditUser;

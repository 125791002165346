import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getRol, getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getIngresoFinancieroAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';

import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';
import { getUserListAsync } from '../../../api/Users';

const IngresoFinanciero = () => {
	const [data, setData] = useState([]);

	const [sucursalName, setSucursalName] = useState('');
	const [sucursalList, setSucursalList] = useState([]);

	const [userList, setUserList] = useState([]);
	const [userName, setUserName] = useState([]);

	const compRef = useRef();
	const { selectedUser, selectedSucursal, fechaDesde, fechaHasta, moneda } = useParams();

	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const token = getToken();
	const user = getUser();
	const rol = getRol();

	useEffect(() => {
		(async () => {
			//   let sucursals = await getSucursals();
			let users = await getUsers();

			//   if (selectedSucursal === "t") {
			//     setSucursalName("Todas");
			//   } else {
			//     let name = sucursals.filter(
			//       (item) => item.id.toString() === selectedSucursal
			//     );
			//     setSucursalName(name[0].description);
			//   }

			if (selectedUser === 't') {
				setUserName('Todos');
			} else {
				let name = users.filter((item) => item.id.toString() === selectedUser);
				// let name = users.filter((item) => {
				// 	console.log(item.id.toString() === selectedUser);
				// });
				setUserName(name[0].fullName);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				// sucursalId: selectedSucursal === "t" ? 0 : selectedSucursal,
				gestorId: 1,
				userId: selectedUser === 't' ? 0 : selectedUser
			};

			setIsLoading(true);
			const result = await getIngresoFinancieroAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);

			setData(result.data.result);
		})();
	}, []);

	//   const getSucursals = async () => {
	//     setIsLoading(true);
	//     const result = await getSucursalByUserAsync(token);
	//     if (!result.statusResponse) {
	//       setIsLoading(false);
	//       if (result.error === "Unauthorized") {
	//         setIsUnautorized(true);
	//         return;
	//       }
	//       simpleMessage(result.error, "error");
	//       return;
	//     }
	//     setIsLoading(false);
	//     setSucursalList(
	//       selectedSucursal === "t"
	//         ? result.data.result
	//         : result.data.result.filter((g) => g.id.toString() === selectedSucursal)
	//     );

	//     return result.data.result;
	//   };

	const getUsers = async () => {
		setIsLoading(true);
		const result = await getUserListAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setUserList(result.data.result);
		setIsLoading(false);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 5mm; }
`;

	let totalCapConsol = 0;
	let totalICConsol = 0;
	let totalCobradoConsol = 0;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			let obj = {
				transaccion: item.id,
				cliente: item.fullName,
				creditoId: item.creditId,
				fechaHoraPago: moment(item.fechaPago).format('DD/MM/yyyy hh:mm'),
				AbonoCapital: item.aCapital,
				abonoInteres: item.aIntCorriente,
				totalRecibido: item.totalRecibido,
				realizadoPor: item.gestor
				// sucursal: item.sucursal,
				// moneda: moneda === 1 ? "Cordobas" : "Dolares",
			};
			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'IngresosFinancieros');
		XLSX.writeFile(bk, 'IngresosFinancieros.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Ingresos Financieros
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>

								{/* <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography> */}

								<Typography fontSize={11} textAlign="left">
									{`Usuario: ${selectedUser === 't' ? 'Todos' : userName}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>

								{/* <Typography fontSize={11} textAlign="left">
									{`Moneda: ${parseInt(moneda) === 1 ? 'Cordoba' : 'Dolar'}`}
								</Typography> */}
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								{/* <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography> */}
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												Transacción
											</TableCell>

											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												Cliente
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												#Crédito
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												F/H Pago
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												A Capital
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												A.Int.Corriente
											</TableCell>

											<TableCell
												align="center"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												Total Recibido
											</TableCell>

											<TableCell
												align="left"
												className="text-primary fw-bold"
												style={{ fontSize: 12 }}
											>
												Realizado por
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{data.map((row) => {
											const { aCapital, aIntCorriente, totalRecibido } = row;
											totalCapConsol += aCapital;
											totalICConsol += aIntCorriente;
											totalCobradoConsol += totalRecibido;
											return (
												<TableRow key={row.id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{row.id}
													</TableCell>

													<TableCell
														align="left"
														style={{ fontSize: 11 }}
													>
														{row.fullName}
													</TableCell>

													<TableCell
														align="center"
														style={{ fontSize: 11 }}
													>
														{row.creditId}
													</TableCell>
													<TableCell
														align="center"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{moment(row.fechaPago).format(
															'DD/MM/yyyy hh:mm:ss A'
														)}
													</TableCell>
													<TableCell
														align="center"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.aCapital)}
													</TableCell>
													<TableCell
														align="center"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.aIntCorriente)}
													</TableCell>
													<TableCell
														align="center"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.totalRecibido)}
													</TableCell>
													<TableCell
														align="left"
														scope="row"
														style={{ fontSize: 11 }}
													>
														{row.gestor}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
						</>
					)}

					<Stack>
						<Typography
							sx={{
								color: '#2196f3',
								textAlign: 'center'
							}}
						>
							Consolidado
						</Typography>

						<hr />

						<Stack direction="row" flex="row" justifyContent="space-around">
							<Stack textAlign="center">
								<Typography
									style={{
										fontWeight: 'bold',
										color: '#03a9f4',
										fontSize: 11
									}}
								>
									Total Cobros
								</Typography>
								<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
							</Stack>

							<Stack textAlign="center">
								<Typography
									style={{
										fontWeight: 'bold',
										color: '#03a9f4',
										fontSize: 11
									}}
								>
									Total A Capital
								</Typography>
								<Typography style={{ fontSize: 11 }}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(totalCapConsol)}
								</Typography>
							</Stack>

							<Stack textAlign="center">
								<Typography
									style={{
										fontWeight: 'bold',
										color: '#03a9f4',
										fontSize: 11
									}}
								>
									Total A Int. Cnte.
								</Typography>
								<Typography style={{ fontSize: 11 }}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(totalICConsol)}
								</Typography>
							</Stack>

							<Stack textAlign="center">
								<Typography
									style={{
										fontWeight: 'bold',
										color: '#03a9f4',
										fontSize: 11
									}}
								>
									Total Cobrado
								</Typography>
								<Typography style={{ fontSize: 11 }}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(totalCobradoConsol)}
								</Typography>
							</Stack>
						</Stack>

						<hr />
					</Stack>
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Ingresos Financieros'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								{/* <Typography fontSize={11} textAlign="left">
                  {`Sucursal: ${
                    selectedSucursal === "t" ? "Todas" : sucursalName
                  }`}
                </Typography> */}
								<Typography fontSize={11} textAlign="left">
									{`Usuario: ${selectedUser === 't' ? 'Todas' : userName}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Moneda: ${moneda === 0 ? 'Cordoba' : 'Dolar'}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								{/* <Typography fontSize={11} textAlign="left">
                  {rol}
                </Typography> */}
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>

						<Container maxWidth={false} sx={{ textAlign: 'center' }}>
							<hr />

							{isEmpty(data) ? (
								<NoData />
							) : (
								<>
									<TableContainer>
										<Table aria-label="simple table" size="small">
											<TableHead>
												<TableRow>
													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														Transacción
													</TableCell>

													<TableCell
														align="left"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														Cliente
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														#Crédito
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														Fecha Pago
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														A Capital
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														A.Int.Corriente
													</TableCell>

													<TableCell
														align="center"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														Total Recibido
													</TableCell>

													<TableCell
														align="left"
														className="text-primary fw-bold"
														style={{ fontSize: 11 }}
													>
														Realizado por
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{data.map((row) => {
													return (
														<TableRow key={row.id}>
															<TableCell
																align="center"
																component="th"
																scope="row"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{row.id}
															</TableCell>

															<TableCell
																align="left"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{row.fullName}
															</TableCell>

															<TableCell
																align="center"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{row.creditId}
															</TableCell>
															<TableCell
																align="center"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{moment(row.fechaPago).format('L')}
																{/* {moment(row.fechaPago).format(
                                  "DD/MM/yyyy hh:mm:ss A"
                                )} */}
															</TableCell>
															<TableCell
																align="center"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.aCapital)}
															</TableCell>
															<TableCell
																align="center"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.aIntCorriente)}
															</TableCell>
															<TableCell
																align="center"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{new Intl.NumberFormat('es-NI', {
																	style: 'currency',
																	currency: 'NIO'
																}).format(row.totalRecibido)}
															</TableCell>
															<TableCell
																align="left"
																style={{ fontSize: 10 }}
																className="text-dark"
															>
																{row.gestor}
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</TableContainer>
								</>
							)}
						</Container>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center'
								}}
							>
								Consolidado
							</Typography>

							<hr />

							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobros
									</Typography>
									<Typography style={{ fontSize: 11 }}>{data.length}</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Capital
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCapConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Int. Cnte.
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalICConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobrado
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCobradoConsol)}
									</Typography>
								</Stack>
							</Stack>

							<hr />
						</Stack>
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default IngresoFinanciero;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Container,
	IconButton,
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	TextField,
	InputAdornment,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Tooltip
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faCirclePlus,
	faTrashAlt,
	faDonate,
	faCashRegister,
	faExternalLink
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';

import { getRuta, isAccess, simpleMessage } from '../../helpers/Helpers';
import { deleteToken, deleteUserData, getToken } from '../../api/Account';
import { DataContext } from '../../context/DataContext';
import NoData from '../../components/NoData';
import { PaginationComponent } from '../../components/PaginationComponent';
import Swal from 'sweetalert2';
import {
	deleteCreditAsync,
	desembolsarCreditAsync,
	getCreditsEntregadosCancelled,
	getCreditsEntregadosUncancelled,
	getCreditsNoEntregados
} from '../../api/Credits';
import moment from 'moment';
import SmallModal from '../../components/modals/SmallModal';
import AddAbono from './creditDetails/quoteComponents/AddAbono';
import DetalleAbono from './creditDetails/quoteComponents/DetalleAbono';

const CreditLIst = () => {
	const [creditList, setCreditList] = useState([]);
	const [localReload, setLocalReload] = useState(false);
	const [active, setActive] = useState(0);

	const {
		setIsLoading,
		setIsLogged,
		setIsUnautorized,
		access,
		showPrintBillModal,
		setShowPrintBillModal
	} = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();
	let navigate = useNavigate();

	const [searchTerm, setSearchTerm] = useState('');
	const [addQuoteModal, setAddQuoteModal] = useState(false);
	const [id, setId] = useState('');
	const [isNominal, setIsNominal] = useState(false);

	const withSearch = creditList.filter((val) => {
		if (searchTerm === '') {
			return val;
		} else if (
			val.id.toString().includes(searchTerm) ||
			val.client.fullName.toString().includes(searchTerm) ||
			val.client.identification.toString().includes(searchTerm)
		) {
			return val;
		}
	});

	useEffect(() => {
		(async () => {
			if (active === 0) {
				setIsLoading(true);
				const result = await getCreditsEntregadosUncancelled(token);
				if (!result.statusResponse) {
					if (result.error === 'eX01') {
						setIsLoading(false);
						deleteUserData();
						deleteToken();
						setIsLogged(false);
						return;
					}
					setIsLoading(false);
					if (result.error === 'Unauthorized') {
						setIsUnautorized(true);
						return;
					}
					simpleMessage(result.error, 'error');
					return;
				}

				setIsLoading(false);
				setCreditList(result.data.result);
			} else if (active === 1) {
				setIsLoading(true);
				const result = await getCreditsNoEntregados(token);
				if (!result.statusResponse) {
					if (result.error === 'eX01') {
						setIsLoading(false);
						deleteUserData();
						deleteToken();
						setIsLogged(false);
						return;
					}
					setIsLoading(false);
					if (result.error === 'Unauthorized') {
						setIsUnautorized(true);
						return;
					}
					simpleMessage(result.error, 'error');
					return;
				}

				setIsLoading(false);

				setCreditList(result.data.result);
			} else {
				setIsLoading(true);
				const result = await getCreditsEntregadosCancelled(token);
				if (!result.statusResponse) {
					if (result.error === 'eX01') {
						setIsLoading(false);
						deleteUserData();
						deleteToken();
						setIsLogged(false);
						return;
					}
					setIsLoading(false);
					if (result.error === 'Unauthorized') {
						setIsUnautorized(true);
						return;
					}
					simpleMessage(result.error, 'error');
					return;
				}

				setIsLoading(false);
				setCreditList(result.data.result);
			}
		})();
	}, [localReload]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = withSearch.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const onChangeSearch = (val) => {
		setCurrentPage(1);
		setSearchTerm(val);
		paginate(1);
	};

	const deleteCredit = async (item) => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'No podras revertir esto...!',
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Eliminar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				(async () => {
					setIsLoading(true);
					const result = await deleteCreditAsync(token, item.id);
					if (!result.statusResponse) {
						setIsLoading(false);
						if (result.error === 'Unauthorized') {
							setIsUnautorized(true);
							return;
						}
						simpleMessage(result.error, 'error');
						return;
					}
					setIsLoading(false);
					setLocalReload(!localReload);
					Swal.fire('Eliminado!', 'Crédito Eliminado.', 'success');
				})();
			}
		});
	};

	const handleChange = async (event) => {
		setActive(event.target.value);
		if (event.target.value === 0) {
			setIsLoading(true);
			const result = await getCreditsEntregadosUncancelled(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setCreditList(result.data.result);
		} else if (event.target.value === 1) {
			setIsLoading(true);
			const result = await getCreditsNoEntregados(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setCreditList(result.data.result);
		} else {
			setIsLoading(true);
			const result = await getCreditsEntregadosCancelled(token);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}

			setIsLoading(false);
			setCreditList(result.data.result);
		}
	};

	const desembolsarCredito = async (id) => {
		setIsLoading(true);

		const result = await desembolsarCreditAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setActive(0);
		setLocalReload(!localReload);
		navigate(`${ruta}/credit/${id}`);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
						<Typography
							color={'#00a152'}
							variant="h4"
							fontWeight={'bold'}
							className="d-none d-sm-block"
						>
							Lista de Créditos
						</Typography>

						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
							<FormControl style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Estado
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={handleChange}
									value={active}
									label="Estado"
								>
									<MenuItem value={0}>Creditos Entregados</MenuItem>
									<MenuItem value={1}>Pendientes de Entrega</MenuItem>
									<MenuItem value={2}>Cancelados</MenuItem>
								</Select>
							</FormControl>
							{isAccess(access, 'CREDITS CREATE') ? (
								<Button
									onClick={() => {
										navigate(`${ruta}/credit/add/0`);
									}}
									startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
									variant="outlined"
									style={{
										color: '#00a152',
										borderColor: '#00a152'
									}}
								>
									Agregar Crédito
								</Button>
							) : (
								<></>
							)}
						</Stack>
					</Stack>

					<hr />

					<TextField
						style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
						fullWidth
						onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
						value={searchTerm}
						label={'Buscar Credito'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<FontAwesomeIcon
											icon={faSearch}
											style={{ color: '#1769aa' }}
										/>
									</IconButton>
								</InputAdornment>
							)
						}}
					/>

					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="a dense table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												#
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Cliente
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Desembolso
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Cuota
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												F.Entrega
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												F.Vencimiento
											</TableCell>
											{/* <TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Es Nominal
											</TableCell> */}
											{isAccess(access, 'CLIENTS UPDATE') ||
											isAccess(access, 'CREDITS VER') ||
											isAccess(access, 'CREDITS CREATE') ||
											isAccess(access, 'CLIENTS DELETE') ? (
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													Acciones
												</TableCell>
											) : (
												<></>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{currentItem.map((row) => {
											const {
												id,
												client,
												montoEntregado,
												fechaEntrega,
												fechaVencimiento,
												cuotaPagar,
												isNominal
											} = row;

											return (
												<TableRow key={id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{id}
													</TableCell>
													<TableCell
														align="left"
														component="th"
														scope="row"
													>
														{client.fullName}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(montoEntregado)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(cuotaPagar)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{moment(fechaEntrega).format('L')}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{moment(fechaVencimiento).format('L')}
													</TableCell>
													{/* <TableCell
														align="center"
														component="th"
														scope="row"
													>
														{isNominal ? 'SI' : 'NO'}
													</TableCell> */}
													{isAccess(access, 'CREDITS UPDATE') ||
													isAccess(access, 'CREDITS CREATE') ||
													isAccess(access, 'CREDITS DELETE') ? (
														<TableCell align="center">
															<Stack
																direction={'row'}
																spacing={1}
																justifyContent="center"
															>
																{isAccess(access, 'CREDITS VER') ? (
																	<IconButton
																		style={{
																			color: '#ffc400'
																		}}
																		onClick={() => {
																			navigate(
																				`${ruta}/credit/${row.id}`
																			);
																		}}
																	>
																		<FontAwesomeIcon
																			icon={faExternalLink}
																		/>
																	</IconButton>
																) : (
																	<></>
																)}

																{active === 0 ? (
																	<Tooltip title="Abonar">
																		<IconButton
																			style={{
																				color: '#00a152'
																			}}
																			onClick={() => {
																				setId(row.id);
																				setIsNominal(
																					row.isNominal
																				);
																				setAddQuoteModal(
																					true
																				);
																			}}
																		>
																			<FontAwesomeIcon
																				icon={
																					faCashRegister
																				}
																			/>
																		</IconButton>
																	</Tooltip>
																) : active === 1 ? (
																	<Tooltip title="Desembolsar">
																		<IconButton
																			style={{
																				color: '#ff9100'
																			}}
																			onClick={() =>
																				desembolsarCredito(
																					row.id
																				)
																			}
																		>
																			<FontAwesomeIcon
																				icon={faDonate}
																			/>
																		</IconButton>
																	</Tooltip>
																) : (
																	<></>
																)}

																{isAccess(
																	access,
																	'CREDITS DELETE'
																) ? (
																	<IconButton
																		style={{
																			color: '#f50057',
																			width: 40,
																			height: 40
																		}}
																		onClick={() =>
																			deleteCredit(row)
																		}
																	>
																		<FontAwesomeIcon
																			icon={faTrashAlt}
																		/>
																	</IconButton>
																) : (
																	<></>
																)}
															</Stack>
														</TableCell>
													) : (
														<></>
													)}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<PaginationComponent
								data={withSearch}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>

			<SmallModal
				titulo={'Agregar Abono'}
				isVisible={addQuoteModal}
				setVisible={setAddQuoteModal}
			>
				<AddAbono
					setShowModal={setAddQuoteModal}
					id={id}
					localReload={localReload}
					setLocalReload={setLocalReload}
					isNominal={isNominal}
				/>
			</SmallModal>

			<SmallModal
				titulo={'Detalle Abono'}
				isVisible={showPrintBillModal}
				setVisible={setShowPrintBillModal}
			>
				<DetalleAbono setShowModal={setShowPrintBillModal} />
			</SmallModal>
		</div>
	);
};

export default CreditLIst;

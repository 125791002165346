import React, { useState, useEffect, useContext } from 'react';

import { Button, Paper, Stack, Typography, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../../../../../context/DataContext';
import { simpleMessage } from '../../../../../helpers/Helpers';
import { getToken } from '../../../../../api/Account';

import {
	getMontoGastosAdministrativosAsync,
	getMontoGastosOperativosAsync,
	getMontoOtrosGastosAsync,
	getSaldoInicialAsync,
	getMontoDesembolsosAsync
} from '../../../../../api/ArqueoCaja';
import moment from 'moment';
const CashRegisterAddDetail = ({
	fechaDesde,
	setFechaDesde,
	recuperacion,

	saveArqueo,
	gastosAdministrativos,
	setGastosAdministrativos,
	gastosOperativos,
	setGastosOperativos,
	otrosGastos,
	setOtrosGastos,
	otrosIngresos,

	setSaldoFinal,
	getRecuperacion,
	desembolsos,
	setDesembolsos
}) => {
	const [saldoInicial, setSaldoInicial] = useState(0);

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const token = getToken();

	useEffect(() => {
		getSaldoInicial();
		getGastosAdministrativos();
		getGastosOperativos();
		getOtrosGastos();
		getDesembolsos();
		getRecuperacion();
	}, [fechaDesde]);

	const getDesembolsos = async () => {
		setIsLoading(true);
		const result = await getMontoDesembolsosAsync(
			token,
			moment(fechaDesde).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setDesembolsos(result.data.result);
		setIsLoading(false);
	};

	const getGastosAdministrativos = async () => {
		setIsLoading(true);
		const result = await getMontoGastosAdministrativosAsync(
			token,
			moment(fechaDesde).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setGastosAdministrativos(result.data.result);
		setIsLoading(false);
	};

	const getGastosOperativos = async () => {
		setIsLoading(true);
		const result = await getMontoGastosOperativosAsync(
			token,
			moment(fechaDesde).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setGastosOperativos(result.data.result);
		setIsLoading(false);
	};

	const getOtrosGastos = async () => {
		setIsLoading(true);
		const result = await getMontoOtrosGastosAsync(
			token,
			moment(fechaDesde).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setOtrosGastos(result.data.result);
		setIsLoading(false);
	};

	const getSaldoInicial = async () => {
		setIsLoading(true);
		const result = await getSaldoInicialAsync(token, moment(fechaDesde).format('YYYY-MM-DD'));
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setSaldoInicial(result.data.result);
		setIsLoading(false);
	};

	const getSaldoFinal = () => {
		let otherIns = otrosIngresos === '' ? 0 : parseFloat(otrosIngresos);
		let sF =
			saldoInicial +
			recuperacion +
			otherIns -
			(gastosAdministrativos + gastosOperativos + otrosGastos + desembolsos);
		setSaldoFinal(sF);
		return sF;
	};

	const getTotalIngresos = () => {
		let otherIns = otrosIngresos === '' ? 0 : parseFloat(otrosIngresos);
		let sF = recuperacion + otherIns;
		return sF;
	};

	return (
		<div>
			<Paper
				style={{
					padding: 20
				}}
			>
				<Typography color={'#00a152'} fontWeight={'bold'}>
					Detalle Arqueo
				</Typography>

				<hr />
				<Stack spacing={2}>
					<DesktopDatePicker
						closeOnSelect
						label="fecha Cierre"
						inputFormat="DD/MM/YYYY"
						value={fechaDesde}
						onChange={(newValue) => setFechaDesde(newValue)}
						renderInput={(params) => <TextField fullWidth {...params} />}
					/>

					{/* <Paper
						style={{
							padding: 20
						}}
					>
						<Typography color={'#00a152'} fontWeight={'bold'}>
							Detalle Ingresos
						</Typography>

						<hr />
						<Stack spacing={2}>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
								justifyContent={'space-between'}
							>
								<Typography fontWeight={'bold'}>Total Billetaje</Typography>
								<Typography color={'#00a152'} fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(totalEfectivo)}
								</Typography>
							</Stack>

							
						</Stack>
					</Paper> */}

					<Paper
						style={{
							padding: 20
						}}
					>
						<Typography color={'#00a152'} fontWeight={'bold'}>
							Detalle Egresos
						</Typography>

						<hr />
						<Stack spacing={2}>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
								justifyContent={'space-between'}
							>
								<Typography fontWeight={'bold'}>Desembolsos del Dia</Typography>
								<Typography color={'#ffc400'} fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(desembolsos)}
								</Typography>
							</Stack>

							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
								justifyContent={'space-between'}
							>
								<Typography fontWeight={'bold'}>Gastos Administrativos</Typography>
								<Typography color={'#ffc400'} fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(gastosAdministrativos)}
								</Typography>
							</Stack>

							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
								justifyContent={'space-between'}
							>
								<Typography fontWeight={'bold'}>Gastos Operativos</Typography>
								<Typography color={'#ffc400'} fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(gastosOperativos)}
								</Typography>
							</Stack>

							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								alignItems="center"
								spacing={1}
								justifyContent={'space-between'}
							>
								<Typography fontWeight={'bold'}>Otros Gastos</Typography>
								<Typography color={'#ffc400'} fontWeight={'bold'}>
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(otrosGastos)}
								</Typography>
							</Stack>
						</Stack>
					</Paper>

					<Paper
						style={{
							padding: 20
						}}
					>
						<Stack spacing={2}>
							<Stack direction="row" justifyContent={'space-between'}>
								<Typography fontWeight={'bold'} textAlign={'left'}>
									SALDO INICIAL
								</Typography>
								<Typography color={'#00a152'} fontWeight={'bold'} textAlign="right">
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(saldoInicial)}
								</Typography>
							</Stack>

							<Stack direction="row" justifyContent={'space-between'}>
								<Typography fontWeight={'bold'} textAlign={'left'}>
									TOTAL INGRESOS:
								</Typography>
								<Typography color={'#00a152'} fontWeight={'bold'} textAlign="right">
									+{' '}
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(getTotalIngresos())}
								</Typography>
							</Stack>

							<Stack direction="row" justifyContent={'space-between'}>
								<Typography fontWeight={'bold'} textAlign={'left'}>
									TOTAL EGRESOS:
								</Typography>
								<Typography color={'#f50057'} fontWeight={'bold'} textAlign="right">
									-{' '}
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(
										desembolsos +
											gastosAdministrativos +
											gastosOperativos +
											otrosGastos
									)}
								</Typography>
							</Stack>

							<hr />
							<Stack direction="row" justifyContent={'space-between'}>
								<Typography fontWeight={'bold'} textAlign={'left'}>
									SALDO FINAL:
								</Typography>
								<Typography color={'#00a152'} fontWeight={'bold'} textAlign="right">
									{new Intl.NumberFormat('es-NI', {
										style: 'currency',
										currency: 'NIO'
									}).format(getSaldoFinal())}
								</Typography>
							</Stack>
						</Stack>
					</Paper>

					<Button
						onClick={() => {
							saveArqueo();
						}}
						style={{
							color: '#2979ff',
							borderColor: '#2979ff',
							padding: 13,
							borderRadius: 30
						}}
						variant="outlined"
						fullWidth
					>
						<FontAwesomeIcon style={{ marginRight: 10, fontSize: 20 }} icon={faSave} />
						Guardar Arqueo
					</Button>
				</Stack>
			</Paper>
		</div>
	);
};

export default CashRegisterAddDetail;

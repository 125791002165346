import React, { useState, useEffect, useContext } from 'react';
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Paper,
	Container,
	Button,
	Stack,
	TextField
} from '@mui/material';
import { DataContext } from '../../../../context/DataContext';
import { getToken } from '../../../../api/Account';
import { getUserListAsync } from '../../../../api/Users';
import { getRuta, simpleMessage } from '../../../../helpers/Helpers';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment/moment';

const SelectorIngresosFinancieros = () => {
	//   const [sucursalList, setSucursalList] = useState([]);
	//   const [selectedSucursal, setSelectedSucursal] = useState("t");
	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
	const [fechaHasta, setFechaHasta] = useState(date);
	const [moneda, setMoneda] = useState(1);

	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState('t');

	const { setIsLoading, setIsUnautorized } = useContext(DataContext);
	let ruta = getRuta();
	const token = getToken();

	useEffect(() => {
		getUsers();
	}, []);

	const verReport = () => {
		if (!moment(fechaDesde).isValid()) {
			simpleMessage('Seleccione una fecha inicial válida', 'error');
			return;
		}
		if (!moment(fechaHasta).isValid()) {
			simpleMessage('Seleccione una fecha final válida', 'error');
			return;
		}

		var params = `${selectedUser}/${moment(fechaDesde).format('YYYY-MM-DD')}/${moment(
			fechaHasta
		).format('YYYY-MM-DD')}/${moneda}`;
		window.open(`${ruta}/r-ingresofinanciero/${params}`);
	};

	//   const getSucursals = async () => {
	//     setIsLoading(true);
	//     const result = await getSucursalByUserAsync(token);
	//     if (!result.statusResponse) {
	//       setIsLoading(false);
	//       if (result.error === "Unauthorized") {
	//         setIsUnautorized(true);
	//         return;
	//       }
	//       simpleMessage(result.error, "error");
	//       return;
	//     }
	//     setIsLoading(false);
	//     setSucursalList(result.data.result);
	//   };

	const getUsers = async () => {
		setIsLoading(true);
		const result = await getUserListAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setUserList(result.data.result);
	};

	return (
		<div>
			<Container style={{ width: 550 }}>
				<Paper
					elevation={10}
					style={{
						padding: 20
					}}
				>
					<Stack spacing={2}>
						{/* <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setSelectedSucursal(e.target.value)}
                value={selectedSucursal}
                label="Sucursal"
              >
                {sucursalList.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.description}
                    </MenuItem>
                  );
                })}

                {sucursalList.length > 1 ? (
                  <MenuItem key={"t"} value={"t"}>
                    TODAS...
                  </MenuItem>
                ) : (
                  <></>
                )}
              </Select>
            </FormControl> */}

						<FormControl fullWidth style={{ textAlign: 'left' }}>
							<InputLabel id="demo-simple-select-standard-label">Usuarios</InputLabel>
							<Select
								labelId="demo-simple-select-standard-label"
								id="demo-simple-select-standard"
								onChange={(e) => setSelectedUser(e.target.value)}
								value={selectedUser}
								label="Usuarios"
							>
								{userList.map((item) => {
									return (
										<MenuItem key={item.id} value={item.id}>
											{item.fullName}
										</MenuItem>
									);
								})}
								<MenuItem key={'t'} value={'t'}>
									TODOS...
								</MenuItem>
							</Select>
						</FormControl>

						{/* <Stack spacing={2} direction={"row"}> */}
						<DesktopDatePicker
							closeOnSelect
							label="Desde"
							inputFormat="DD/MM/YYYY"
							value={fechaDesde}
							onChange={(newValue) => setFechaDesde(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>

						<DesktopDatePicker
							closeOnSelect
							label="Hasta"
							inputFormat="DD/MM/YYYY"
							value={fechaHasta}
							onChange={(newValue) => setFechaHasta(newValue)}
							renderInput={(params) => <TextField {...params} />}
						/>
						{/* </Stack> */}

						<Button
							variant="outlined"
							startIcon={
								<FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
							}
							fullWidth
							onClick={() => verReport()}
							style={{
								color: '#4caf50',
								borderColor: '#4caf50'
							}}
							size="large"
						>
							Generar Reporte
						</Button>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default SelectorIngresosFinancieros;

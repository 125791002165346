import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Container,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Select,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faChevronCircleLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { getRuta, simpleMessage } from "../../../helpers/Helpers";
import { deleteToken, deleteUserData, getToken } from "../../../api/Account";
import { getRolListAsync } from "../../../api/Rols";
import { addUserAsync, getJefesListAsync } from "../../../api/Users";

const AddUser = () => {
  const token = getToken();
  let ruta = getRuta();
  let navigate = useNavigate();
  const { setIsLoading, setIsLogged, setIsUnautorized } =
    useContext(DataContext);

  const [showPassword, setShowPassword] = useState(false);

  const [rolesList, setRolesList] = useState([]);
  const [jefeList, setJefeList] = useState([]);

  const [selectedRol, setSelectedRol] = useState("");
  const [selectedJefe, setSelectedJefe] = useState("");

  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [lastName, setLastName] = useState("");
  const [secondLastName, setSecondLastName] = useState("");

  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getRolListAsync(token);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setRolesList(result.data.result);

      setIsLoading(false);
    })();
  }, []);

  const handleChangeRol = async (event) => {
    let selected = event.target.value;
    setSelectedJefe("");
    setSelectedRol(selected);
    const result = await getJefesListAsync(token, selected);
    if (!result.statusResponse) {
      if (result.error === "eX01") {
        setIsLoading(false);
        deleteUserData();
        deleteToken();
        setIsLogged(false);
        return;
      }
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);
    setJefeList(result.data.result);
    setIsLoading(false);
  };

  const addNewUser = async () => {
    if (validate()) {
      const datos = {
        firstName,
        secondName,
        lastName,
        secondLastName,
        userName,
        passWord,
        phone,
        address,
        supervisorId: selectedJefe === "" ? selectedRol : selectedJefe,
        idRole: selectedRol,
      };
      setIsLoading(true);
      const result = await addUserAsync(token, datos);
      if (!result.statusResponse) {
        setIsLoading(false);

        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }

        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      clearData();
      simpleMessage("Usuario Agregado...!", "success");
    }
  };

  const validate = () => {
    let isValid = true;
    if (isEmpty(firstName)) {
      simpleMessage("Debe ingresar un nombre", "error");
      return (isValid = false);
    }

    if (isEmpty(lastName)) {
      simpleMessage("Debe ingresar un apellido", "error");
      return (isValid = false);
    }

    if (selectedJefe === "") {
      if (selectedRol !== 2) {
        simpleMessage("Debe seleccionar un puesto de trabajo", "error");
        return (isValid = false);
      }
    }

    if (isEmpty(userName)) {
      simpleMessage("Debe ingresar usuario de acceso", "error");
      return (isValid = false);
    }

    if (isEmpty(passWord)) {
      simpleMessage("Debe ingresar una contraseña", "error");
      return (isValid = false);
    }
    if (passWord.length < 6) {
      simpleMessage(
        "Debe ingresar una contraseña mayor de 6 caracteres",
        "error"
      );
      return (isValid = false);
    }
    if (isEmpty(confirmPassword)) {
      simpleMessage("Debe confirmar su contraseña", "error");
      return (isValid = false);
    }

    if (passWord !== confirmPassword) {
      simpleMessage("La confirmacion de contrseña no coincide", "error");
      return (isValid = false);
    }

    if (selectedRol === "") {
      simpleMessage("Debe seleccionar un rol de usuario", "error");
      return (isValid = false);
    }
    if (isEmpty(phone)) {
      simpleMessage("Debe ingresar un numero telefonico", "error");
      return (isValid = false);
    }
    return isValid;
  };

  const clearData = () => {
    setFirstName("");
    setSecondName("");
    setLastName("");
    setSecondLastName("");
    setPhone("");
    setAddress("");
    setSelectedRol("");
    setSelectedJefe("");
    setUserName("");
    setPassWord("");
    setConfirmPassword("");
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={() => {
                navigate(`${ruta}/users`);
              }}
              style={{
                color: "#2979ff",
                borderColor: "#2979ff",
              }}
              variant="outlined"
            >
              <FontAwesomeIcon
                style={{ marginRight: 10, fontSize: 20 }}
                icon={faChevronCircleLeft}
              />
              Regresar
            </Button>

            <Typography color={"#00a152"} variant="h4" fontWeight={"bold"}>
              Agregar Usuario
            </Typography>
          </Stack>

          <hr />

          <Typography
            variant="h6"
            color={"#1c54b2"}
            style={{ textAlign: "left" }}
          >
            Datos del Usuario
          </Typography>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setFirstName(e.target.value.toUpperCase())}
                  value={firstName}
                  label={"Primer Nombre"}
                />

                <TextField
                  fullWidth
                  onChange={(e) => setSecondName(e.target.value.toUpperCase())}
                  value={secondName}
                  label={"Segundo Nombre"}
                />
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setLastName(e.target.value.toUpperCase())}
                  value={lastName}
                  label={"Primer apellido"}
                />
                <TextField
                  fullWidth
                  onChange={(e) =>
                    setSecondLastName(e.target.value.toUpperCase())
                  }
                  value={secondLastName}
                  label={"Segundo Apellido"}
                />

                <TextField
                  fullWidth
                  required
                  onChange={(e) => setPhone(e.target.value.toUpperCase())}
                  value={phone}
                  label={"Telefono"}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  required
                  fullWidth
                  onChange={(e) => setUserName(e.target.value.toLowerCase())}
                  value={userName}
                  label={"Usuario"}
                />

                <TextField
                  required
                  fullWidth
                  onChange={(e) => setPassWord(e.target.value)}
                  value={passWord}
                  label={"Contraseña"}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visivility"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ color: "#105155" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              style={{ color: "#105155" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  required
                  fullWidth
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                  label={"Confirmar contraseña"}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visivility"
                        >
                          {showPassword ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ color: "#105155" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              style={{ color: "#105155" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-standard-label">
                    Seleccione un rol
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedRol}
                    onChange={handleChangeRol}
                    label="Rol de usuario"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un rol de usuario</em>
                    </MenuItem>
                    {rolesList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.rolName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-standard-label">
                    Jefe Inmediato
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={selectedJefe}
                    onChange={(e) => setSelectedJefe(e.target.value)}
                    label="Jefe Inmediato"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un Jefe Inmediato</em>
                    </MenuItem>
                    {jefeList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.fullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                onChange={(e) => setAddress(e.target.value.toUpperCase())}
                value={address}
                label={"Direccion"}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={
                  <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
                }
                fullWidth
                onClick={() => addNewUser()}
                style={{
                  color: "#00a152",
                  borderColor: "#00a152",
                }}
              >
                Agregar usuario
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default AddUser;

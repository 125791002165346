import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  FormControl,
  Select,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Stack } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPenToSquare,
  faCodePullRequest,
} from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../api/Account";
import { DataContext } from "../../../context/DataContext";

import { isAccess, simpleMessage } from "../../../helpers/Helpers";

import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  getCreditByIdAsync,
  remakeCreditAsync,
  updateCreditAsync,
} from "../../../api/Credits";
import { getPeriodicidadListAsync } from "../../../api/Periodicidad";
import { getGestoresAsync } from "../../../api/Users";
import { getInteresListAsync } from "../../../api/InteresCorriente";
import { getPlazoByPeriodicidadAsync } from "../../../api/Plazo";
import Swal from "sweetalert2";

const CreditDetails = ({
  id,
  setClientName,
  isNominal,
  setIsNominal,
  setDataCredit,
}) => {
  const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
  const token = getToken();

  const [selectedClient, setSelectedClient] = useState(null);

  const [periodicidadList, setPeriodicidadList] = useState([]);
  const [selectedPeriodicidad, setSelectedPeriodicidad] = useState("");

  const [plazoList, setPlazoList] = useState([]);
  const [selectedPlazo, setSelectedPlazo] = useState("");

  const [interesCorrienteList, setInteresCorrienteList] = useState([]);
  const [selectedInteresCorriente, setSelectedInteresCorriente] = useState("");

  const [gestorList, setGestorList] = useState([]);
  const [selectedGestor, setSelectedGestor] = useState("");
  const [montoPrincipal, setMontoPrincipal] = useState("");
  const [destino, setDestino] = useState("");

  const [fechaPrimeraCuota, setFechaPrimeraCuota] = useState(new Date());
  const [fechaEntrega, setFechaEntrega] = useState(new Date());

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getCreditByIdAsync(token, id);

      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setClientName(result.data.result.client.fullName);
      setDataCredit(result.data.result);
      setSelectedClient(result.data.result.client);

      const resultPer = await getPeriodicidadListAsync(token);
      if (!resultPer.statusResponse) {
        setIsLoading(false);
        if (resultPer.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(resultPer.error, "error");
        return;
      }
      setPeriodicidadList(resultPer.data.result);

      const resultGestores = await getGestoresAsync(token);
      if (!resultGestores.statusResponse) {
        setIsLoading(false);
        if (resultGestores.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(resultGestores.error, "error");
        return;
      }
      setGestorList(resultGestores.data.result);

      const resultIC = await getInteresListAsync(token);
      if (!resultIC.statusResponse) {
        setIsLoading(false);
        if (resultIC.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(resultIC.error, "error");
        return;
      }
      setInteresCorrienteList(resultIC.data.result);

      const resultPlazo = await getPlazoByPeriodicidadAsync(
        token,
        result.data.result.periocidad.id
      );
      if (!resultPlazo.statusResponse) {
        setIsLoading(false);
        if (resultPlazo.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(resultPlazo.error, "error");
        return;
      }

      setPlazoList(resultPlazo.data.result);
      setMontoPrincipal(result.data.result.montoPrincipal);
      setSelectedGestor(result.data.result.gestor);
      setDestino(result.data.result.destino);
      setSelectedPeriodicidad(result.data.result.periocidad.id);
      setSelectedPlazo(result.data.result.plazo.id);
      setSelectedInteresCorriente(result.data.result.tasaInteresCorriente.id);
      setFechaEntrega(new Date(result.data.result.fechaEntrega));
      setFechaPrimeraCuota(new Date(result.data.result.fechaPrimeraCuota));
      setIsNominal(result.data.result.isNominal);
      setIsLoading(false);
    })();
  }, []);

  //Devuelve un entero positivo
  const funtionSetMontoSolicitado = (value) => {
    if (/^[0-9]+$/.test(value.toString()) || value === "") {
      setMontoPrincipal(value);
      return;
    }
  };

  const handleChangePeriodicidad = async (event) => {
    const selected = periodicidadList.filter(
      (item) => item.id == event.target.value
    );
    let dias = selected[0].cuotaDias;

    var newDate = new Date();
    newDate.setDate(newDate.getDate() + dias);

    setFechaPrimeraCuota(newDate);
    setPlazoList([]);
    setSelectedPeriodicidad(event.target.value);

    if (event.target.value !== "") {
      setIsLoading(true);
      const result = await getPlazoByPeriodicidadAsync(
        token,
        event.target.value
      );
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setPlazoList(result.data.result);
      setIsLoading(false);
    } else {
      setPlazoList([]);
    }
  };

  const saveChanges = async () => {
    if (validate()) {
      const datos = {
        id,
        montoPrincipal,
        fechaEntrega,
        fechaPrimeraCuota,
        destino,
        periocidadId: selectedPeriodicidad,
        plazoId: selectedPlazo,
        interesCorrienteId: selectedInteresCorriente,
        gestor: selectedGestor,
      };

      setIsLoading(true);
      const result = await updateCreditAsync(token, datos);
      if (!result.statusResponse) {
        setIsLoading(false);

        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }

        simpleMessage(result.error, "error");
        return;
      }

      setIsLoading(false);
      setIsEdit(false);
      simpleMessage("Crédito actualizado...!", "success");
    }
  };

  //Validando campos ingresados
  const validate = () => {
    let isValid = true;
    if (isEmpty(selectedClient)) {
      simpleMessage("Debe seleccionar un cliente", "error");
      return (isValid = false);
    }

    if (montoPrincipal === "") {
      simpleMessage("Debe ingresar un monto", "error");
      return (isValid = false);
    }

    if (fechaPrimeraCuota === "") {
      simpleMessage("Debe ingresar fecha de primera cupta", "error");
      return (isValid = false);
    }

    if (fechaPrimeraCuota === "") {
      simpleMessage("Debe ingresar una fecha de entrega", "error");
      return (isValid = false);
    }

    if (selectedGestor === "") {
      simpleMessage("Debe seleccionar un gestor", "error");
      return (isValid = false);
    }

    if (selectedPlazo === "") {
      simpleMessage("Debe seleccionar un plazo", "error");
      return (isValid = false);
    }

    if (selectedInteresCorriente === "") {
      simpleMessage("Debe seleccionar una tasa de interes", "error");
      return (isValid = false);
    }

    if (isEmpty(destino)) {
      simpleMessage("Debe seleccionar un destino de credito", "error");
      return (isValid = false);
    }
    return isValid;
  };

  const remakeCredit = async () => {
    Swal.fire({
      title: "¿Estas Seguro de Rehacer este Crédito?",
      // text: "...!",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#2979ff",
      cancelButtonColor: "#f50057",

      confirmButtonText: "Rehacer!",
      cancelButtonText: "Cancelar",
      customClass: {
        popup: "border",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          setIsLoading(true);
          const result = await remakeCreditAsync(token, id);
          if (!result.statusResponse) {
            setIsLoading(false);
            if (result.error === "Unauthorized") {
              setIsUnautorized(true);
              return;
            }
            simpleMessage(result.error, "error");
            return;
          }
          Swal.fire("Listo!", "", "success");
          setIsLoading(false);
          setIsEdit(false);
        })();
      }
    });
  };

  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
          }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography color={"#00a152"} variant="h6" fontWeight={"bold"}>
              Datos del Crédito
            </Typography>

            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              {isAccess(access, "CREDITS UPDATE") ? (
                <Button
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                  style={{
                    color: "#ffc400",
                    borderColor: "#ffc400",
                  }}
                  variant="outlined"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: 10, fontSize: 20 }}
                    icon={faPenToSquare}
                  />
                  Editar
                </Button>
              ) : (
                <></>
              )}

              {isNominal ? (
                <FormControlLabel
                  labelPlacement="start"
                  style={{ color: "#2979ff", fontWeight: "bold" }}
                  control={
                    <Checkbox
                      checked={isNominal}
                      sx={{
                        color: "#2979ff",
                        "&.Mui-checked": {
                          color: "#2979ff",
                        },
                      }}
                    />
                  }
                  label="Crédito Nominal"
                />
              ) : isAccess(access, "CREDITS REGEN") ? (
                <Button
                  onClick={() => {
                    remakeCredit();
                  }}
                  style={{
                    color: "#f50057",
                    borderColor: "#f50057",
                  }}
                  variant="outlined"
                >
                  <FontAwesomeIcon
                    style={{ marginRight: 10, fontSize: 20 }}
                    icon={faCodePullRequest}
                  />
                  Rehacer Credito
                </Button>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          <hr />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <TextField
                  disabled={!isEdit}
                  required
                  fullWidth
                  onChange={(e) => funtionSetMontoSolicitado(e.target.value)}
                  value={montoPrincipal}
                  label={"Monto Solicitado"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">C$</InputAdornment>
                    ),
                  }}
                />

                <FormControl
                  disabled={!isEdit}
                  fullWidth
                  style={{ textAlign: "left" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Gestor
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSelectedGestor(e.target.value)}
                    value={selectedGestor}
                    label="Gestor"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un Gestor</em>
                    </MenuItem>
                    {gestorList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.fullName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  disabled={!isEdit}
                  required
                  fullWidth
                  onChange={(e) => setDestino(e.target.value.toUpperCase())}
                  value={destino}
                  label={"Destino"}
                />

                <DesktopDatePicker
                  disabled={!isEdit}
                  closeOnSelect
                  label="Fecha de Entrega"
                  inputFormat="DD/MM/YYYY"
                  value={fechaEntrega}
                  onChange={(newValue) => setFechaEntrega(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <FormControl
                  disabled={!isEdit}
                  fullWidth
                  style={{ textAlign: "left" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Periodicidad
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={handleChangePeriodicidad}
                    value={selectedPeriodicidad}
                    label="Periodicidad"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un Periodo</em>
                    </MenuItem>
                    {periodicidadList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  disabled={!isEdit}
                  fullWidth
                  style={{ textAlign: "left" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Plazo
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => setSelectedPlazo(e.target.value)}
                    value={selectedPlazo}
                    label="Plazo"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione un Plazo</em>
                    </MenuItem>
                    {plazoList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl
                  disabled={!isEdit}
                  fullWidth
                  style={{ textAlign: "left" }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Tasa de interes
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) =>
                      setSelectedInteresCorriente(e.target.value)
                    }
                    value={selectedInteresCorriente}
                    label="Tasa de interes"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em>Seleccione una Tasa de Interes</em>
                    </MenuItem>
                    {interesCorrienteList.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {`${item.intCorriente.toFixed(2)} %`}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <DesktopDatePicker
                  disabled={!isEdit}
                  closeOnSelect
                  label="Fecha Primera Cuota"
                  inputFormat="DD/MM/YYYY"
                  value={fechaPrimeraCuota}
                  onChange={(newValue) => setFechaPrimeraCuota(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </Grid>
          </Grid>

          {isAccess(access, "CREDITS UPDATE") ? (
            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => saveChanges()}
              style={{
                marginTop: 30,
                borderRadius: 20,
                color: "#00a152",
                borderColor: "#00a152",
              }}
              size="large"
              disabled={!isEdit}
            >
              Acualizar Credito
            </Button>
          ) : (
            <></>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default CreditDetails;

import axios from 'axios';
import { errorResponse } from '../helpers/Helpers';
const { REACT_APP_PRODURL, REACT_APP_URL } = process.env;
let Api = '';

if (process.env.NODE_ENV === 'production') {
	Api = `${REACT_APP_PRODURL}treasury`;
} else {
	Api = `${REACT_APP_URL}treasury`;
}

export const getDenominationsCordobaAsync = async (token) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetDenominacionCordoba`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getDenominationsDolarAsync = async (token) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetDenominacionDolar`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getArqueosCajaListAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetArqueoLists/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getMontoDesembolsosAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetMontoDesembolsos/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getMontoRecuperacionAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetMontoRecuperacion/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getMontoGastosAdministrativosAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetMontoGastosAdministrativos/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getMontoGastosOperativosAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetMontoGastosOperativos/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getSaldoInicialAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetSaldoInicial/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getMontoOtrosGastosAsync = async (token, fecha) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetMontoOtrosGastos/${fecha}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const addArqueoAsync = async (token, data) => {
	const result = { statusResponse: true, data: [], error: null };
	let service = `${Api}/AddArqueoGeneral`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.post(service, data).then((resp) => {
			if (resp.status <= 200 && resp.status >= 299) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

export const getArqueoByIdAsync = async (token, id) => {
	const result = { statusResponse: true, data: [], error: null };
	let url = `${Api}/GetArqueoById/${id}`;
	const authAxios = axios.create({
		headers: {
			Authorization: `Bearer ${token}`
		}
	});
	try {
		await authAxios.get(url).then((resp) => {
			if (resp.status !== 200) {
				result.statusResponse = false;
				result.error = resp.title;
			} else {
				result.statusResponse = true;
				result.data = resp.data;
			}
		});
	} catch (error) {
		result.statusResponse = false;
		result.error = errorResponse(error.response);
	}
	return result;
};

import React from 'react';
import { Container, Paper, Typography, Stack, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendarCheck,
	faCalendarXmark,
	faCashRegister,
	faChartPie,
	faChartSimple,
	faCoins,
	faHandHoldingDollar,
	faMoneyBillTrendUp,
	faScaleBalanced,
	faUserCheck,
	faUsers,
	faWallet,
  faCalendar
} from '@fortawesome/free-solid-svg-icons';
import CallerSelector from '../../components/CallerSelector';
import SelectorClientsGestor from './selectores/selectoresCartera/SelectorClientsGestor';
import SelectorCreditsByGestor from './selectores/selectoresCartera/SelectorCreditsByGestor';
import SelectorCobroDiario from './selectores/selectoresCartera/SelectorCobroDiario';
import SelectorRecuperacion from './selectores/selectoresCartera/SelectorRecuperacion';
import SelectorDesembolsos from './selectores/selectoresCartera/SelectorDesembolsos';
import SelectorSaldosCartera from './selectores/selectoresCartera/SelectorSaldosCartera';
import SelectorCreditosVencidos from './selectores/selectoresCartera/SelectorCreditosVencidos';
import SelectorAccountStatus from './selectores/selectoresCartera/SelectorAccountStatus';
import SelectorCierreDiario from './selectores/selectoresCartera/SelectorCierreDiario';
import SelectorEstadoResultado from './selectores/selectoresCartera/SelectorEstadoResultado';
import SelectorCreditsCancelled from './selectores/selectoresCartera/SelectorCreditsCancelled';
import SelectorIngresosFinancieros from './selectores/selectoresCartera/SelectorIngresosFinancieros';
import SelectorVencimientosProyectados from './selectores/selectoresCartera/SelectorVencimientosProyectados';


const Reports = () => {
	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={'row'}>
						<Typography
							color={'#00a152'}
							textAlign="left"
							variant="h4"
							fontWeight={'bold'}
						>
							<FontAwesomeIcon
								icon={faWallet}
								style={{ marginRight: 20 }}
								className="fa-beat"
							/>
						</Typography>
						<Typography
							color={'#00a152'}
							textAlign="left"
							variant="h4"
							fontWeight={'bold'}
							className="d-none d-sm-block"
						>
							Reportes de Cartera
						</Typography>
					</Stack>

					<hr />

					<Grid container spacing={2} justifyContent="center">
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faUsers}
								text="Reporte de Clientes"
								modalTitle="Reporte de Clientes"
							>
								<SelectorClientsGestor />
							</CallerSelector>
						</Grid>

						{/* <Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faHandHoldingDollar}
								text="Créditos por Gestor"
								modalTitle="Créditos por Gestor"
							>
								<SelectorCreditsByGestor />
							</CallerSelector>
						</Grid> */}

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faCalendarCheck}
								text="Cobro Diario"
								modalTitle="Cobro Diario"
							>
								<SelectorCobroDiario />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faUserCheck}
								text="Recuperación"
								modalTitle="Reporte de Recuperacion"
							>
								<SelectorRecuperacion />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faChartSimple}
								text="Ingresos Financieros"
								modalTitle="Ingresos Financieros"
							>
								<SelectorIngresosFinancieros />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faWallet}
								text="Desembolsos"
								modalTitle="Reporte de Desembolsos"
							>
								<SelectorDesembolsos />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faCoins}
								text="Saldos Cartera"
								modalTitle="Reporte Saldos Cartera"
							>
								<SelectorSaldosCartera />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faCalendarXmark}
								text="Créditos Vencidos"
								modalTitle="Reporte Créditos Vencidos"
							>
								<SelectorCreditosVencidos />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faCalendar}
								text="Vencimientos Proyectados"
								modalTitle="Reporte Vencimientos proyectados"
							>
								<SelectorVencimientosProyectados />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faMoneyBillTrendUp}
								text="Créditos Cancelados"
								modalTitle="Reporte Créditos Cancelados"
							>
								<SelectorCreditsCancelled />
							</CallerSelector>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faScaleBalanced}
								text="Estado de Cuenta"
								modalTitle="Reporte de Estado de Cuenta"
							>
								<SelectorAccountStatus />
							</CallerSelector>
						</Grid>

						{/* <Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faCashRegister}
								text="Cierre de Caja"
								modalTitle="Reporte de Cierre de Caja"
							>
								<SelectorCierreDiario />
							</CallerSelector>
						</Grid> */}

						{/* <Grid item xs={12} sm={6} md={4} lg={3}>
							<CallerSelector
								icon={faChartPie}
								text="Estado de Resultado"
								modalTitle="Reporte Estado de Resultado"
							>
								<SelectorEstadoResultado />
							</CallerSelector>
						</Grid> */}
					</Grid>
				</Paper>
			</Container>
		</div>
	);
};

export default Reports;

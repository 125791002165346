import React, { useState, useEffect, useContext, useRef } from 'react';
import {
	Container,
	Stack,
	AppBar,
	Toolbar,
	Typography,
	Dialog,
	IconButton,
	TableContainer,
	Table,
	TableCell,
	TableRow,
	TableHead,
	TableBody,
	Divider
} from '@mui/material';
import { isEmpty, isUndefined } from 'lodash';

import { useParams } from 'react-router-dom';
import { DataContext } from '../../../context/DataContext';

import { getToken, getUser } from '../../../api/Account';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import NoData from '../../../components/NoData';
import { getRecuperacionAsync } from '../../../api/Report';
import { PrintReport } from '../../../components/PrintReport';
import moment from 'moment';
import { getGestoresAsync } from '../../../api/Users';
import { simpleMessage } from '../../../helpers/Helpers';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx/xlsx.mjs';

const Recuperacion = () => {
	const [data, setData] = useState([]);

	const compRef = useRef();
	const { fechaDesde, fechaHasta, selectedGestor } = useParams();
	const { setIsLoading, setIsUnautorized, title, slogan, icon } = useContext(DataContext);

	const [gestorName, setGestorName] = useState('');
	const [gestorList, setGestorList] = useState([]);

	const token = getToken();
	const user = getUser();

	useEffect(() => {
		(async () => {
			let gestores = await getGestores();

			if (selectedGestor === 't') {
				setGestorName('Todos');
			} else {
				let name = gestores.filter((item) => item.id.toString() === selectedGestor);
				setGestorName(name[0].fullName);
			}

			const data = {
				desde: fechaDesde.toString(),
				hasta: fechaHasta.toString(),
				gestorId: selectedGestor === 't' ? 0 : selectedGestor
			};

			setIsLoading(true);
			const result = await getRecuperacionAsync(token, data);
			if (!result.statusResponse) {
				setIsLoading(false);
				if (result.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(result.error, 'error');
				return;
			}
			setIsLoading(false);
			setData(result.data.result);
		})();
	}, []);

	const getGestores = async () => {
		setIsLoading(true);
		const result = await getGestoresAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setIsLoading(false);
		setGestorList(
			selectedGestor === 't'
				? result.data.result
				: result.data.result.filter((g) => g.id.toString() === selectedGestor)
		);
		return result.data.result;
	};

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 3mm; }
`;

	let totalCobrosConsol = 0;
	let totalCapConsol = 0;
	let totalICConsol = 0;

	let totalDiaConsol = 0;
	let totalMoraConsol = 0;
	let totalVencidoConsol = 0;

	let totalCobradoConsol = 0;

	const exportExcel = () => {
		let exp = [];
		data.map((item) => {
			const dataGestor = gestorList.filter((gestor) => gestor.id === item.gestor);
			let gestor = dataGestor[0];
			let obj = {
				transaccion: item.id,
				cliente: item.fullName,
				creditoId: item.creditId,
				fechaPago: moment(item.fechaPago).format('L'),
				AbonoCapital: item.aCapital,
				abonoInteres: item.aIntCorriente,
				dia: item.dia,
				mora: item.mora,
				vencido: item.vencido,
				totalRecibido: item.totalRecibido,
				gestor: isUndefined(gestor) ? item.gestor : gestor.fullName
			};
			exp.push(obj);
		});
		var bk = XLSX.utils.book_new(),
			bs = XLSX.utils.json_to_sheet(exp);

		XLSX.utils.book_append_sheet(bk, bs, 'recuperacion');
		XLSX.writeFile(bk, 'Recuperacion.xlsx');
	};

	return (
		<div>
			<Dialog fullScreen open={true}>
				<AppBar sx={{ position: 'relative' }}>
					<Toolbar>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
						<Typography
							sx={{ ml: 2, flex: 1, textAlign: 'center' }}
							variant="h5"
							component="div"
						>
							{`${title} `}
						</Typography>
						<img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
					</Toolbar>
				</AppBar>

				<Stack display="flex" justifyContent="center">
					<Stack
						direction={'row'}
						justifyContent="space-between"
						paddingLeft={5}
						paddingRight={5}
						marginTop={1}
						marginBottom={1}
					>
						<IconButton variant="outlined" onClick={() => exportExcel()}>
							<FontAwesomeIcon
								icon={faCloudArrowDown}
								style={{ fontSize: 40, color: '#00a152', width: 40 }}
							/>
						</IconButton>

						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
							>
								{slogan}
							</Typography>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center',
									fontWeight: 'bold'
								}}
								variant="h6"
								component="div"
							>
								Reporte de Recuperación
							</Typography>
						</Stack>

						<ReactToPrint
							trigger={() => {
								return (
									<IconButton
										variant="outlined"
										// style={{ position: "fixed", right: 20, top: 75 }}
									>
										<PrintRoundedIcon
											style={{ fontSize: 40, color: '#2979ff', width: 40 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>

					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>

							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>
					</Container>
				</Stack>

				<Container maxWidth={false} sx={{ textAlign: 'center' }}>
					<hr />
					{isEmpty(data) ? (
						<NoData />
					) : (
						<>
							{gestorList.map((gestor) => {
								const cGestor = data.filter((c) => c.gestor === gestor.id);
								let totalCap = 0;
								let totalIC = 0;
								let totalCobrado = 0;

								let totaldia = 0;
								let totalMora = 0;
								let totalVencido = 0;

								cGestor.map((c) => {
									totalCap += c.aCapital;
									totalIC += c.aIntCorriente;
									totalCobrado += c.totalRecibido;

									totaldia += c.dia;
									totalMora += c.mora;
									totalVencido += c.vencido;
								});
								totalCobrosConsol += cGestor.length;
								totalCapConsol += totalCap;
								totalICConsol += totalIC;
								totalCobradoConsol += totalCobrado;

								totalDiaConsol += totaldia;
								totalMoraConsol += totalMora;
								totalVencidoConsol += totalVencido;

								return (
									<Stack key={gestor.id}>
										<Stack direction="row" spacing={1}>
											<Typography
												sx={{
													color: '#2196f3',
													textAlign: 'left'
												}}
												fontSize={11}
											>
												Gestor:
											</Typography>
											<Typography
												sx={{
													textAlign: 'left'
												}}
												fontSize={11}
											>
												{gestor.fullName}
											</Typography>
										</Stack>

										<Divider />

										{isEmpty(cGestor) ? (
											<>
												<Typography
													sx={{
														color: '#2196f3',
														textAlign: 'center',
														fontWeight: 'bold'
													}}
													fontSize={15}
												>
													Gestor no ha hecho ningun cobro!
												</Typography>
												<hr />
											</>
										) : (
											<>
												<TableContainer>
													<Table aria-label="simple table" size="small">
														<TableHead>
															<TableRow>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	Transacción
																</TableCell>
																<TableCell
																	align="left"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	Cliente
																</TableCell>

																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	#Crédito
																</TableCell>

																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	F.Pago
																</TableCell>

																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	A Capital
																</TableCell>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	A.Int.Corriente
																</TableCell>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	A Dia
																</TableCell>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	A Mora
																</TableCell>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	A Vencido
																</TableCell>
																<TableCell
																	align="center"
																	className="text-primary fw-bold"
																	style={{ fontSize: 11 }}
																>
																	Total Recibido
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{cGestor.map((row) => {
																return (
																	<TableRow key={row.id}>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{row.id}
																		</TableCell>
																		<TableCell
																			align="left"
																			style={{ fontSize: 9 }}
																		>
																			{row.fullName}
																		</TableCell>
																		<TableCell
																			align="center"
																			style={{ fontSize: 9 }}
																		>
																			{row.creditId}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{moment(
																				row.fechaPago
																			).format(
																				'DD/MM/yyyy hh:mm:ss A'
																			)}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(row.aCapital)}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.aIntCorriente
																			)}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(row.dia)}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(row.mora)}
																		</TableCell>
																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(row.vencido)}
																		</TableCell>

																		<TableCell
																			align="center"
																			component="th"
																			scope="row"
																			style={{ fontSize: 9 }}
																		>
																			{new Intl.NumberFormat(
																				'es-NI',
																				{
																					style: 'currency',
																					currency: 'NIO'
																				}
																			).format(
																				row.totalRecibido
																			)}
																		</TableCell>
																	</TableRow>
																);
															})}
														</TableBody>
													</Table>
												</TableContainer>

												<hr />
												<Stack
													direction="row"
													flex="row"
													justifyContent="space-around"
												>
													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Total Cobros
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{cGestor.length}
														</Typography>
													</Stack>

													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Total A Capital
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totalCap)}
														</Typography>
													</Stack>

													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Total A Int. Cnte.
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totalIC)}
														</Typography>
													</Stack>

													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Recuperacion Dia
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totaldia)}
														</Typography>
													</Stack>
													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Recuperacion Mora
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totalMora)}
														</Typography>
													</Stack>

													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Recuperacion Venvido
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totalVencido)}
														</Typography>
													</Stack>

													<Stack textAlign="center">
														<Typography
															style={{
																fontWeight: 'bold',
																color: '#03a9f4',
																fontSize: 11
															}}
														>
															Total Cobrado
														</Typography>
														<Typography style={{ fontSize: 11 }}>
															{new Intl.NumberFormat('es-NI', {
																style: 'currency',
																currency: 'NIO'
															}).format(totalCobrado)}
														</Typography>
													</Stack>
												</Stack>
												<hr />
											</>
										)}
									</Stack>
								);
							})}
						</>
					)}

					{gestorList.length === 1 ? (
						<></>
					) : (
						<Stack>
							<Typography
								sx={{
									color: '#2196f3',
									textAlign: 'center'
								}}
							>
								Consolidado
							</Typography>

							<hr />

							<Stack direction="row" flex="row" justifyContent="space-around">
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobros
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{totalCobrosConsol}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Capital
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCapConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total A Int. Cnte.
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalICConsol)}
									</Typography>
								</Stack>

								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Rec Dia
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalDiaConsol)}
									</Typography>
								</Stack>
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Rec Mora
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalMoraConsol)}
									</Typography>
								</Stack>
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Rec Vencido
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalVencidoConsol)}
									</Typography>
								</Stack>
								<Stack textAlign="center">
									<Typography
										style={{
											fontWeight: 'bold',
											color: '#03a9f4',
											fontSize: 11
										}}
									>
										Total Cobrado
									</Typography>
									<Typography style={{ fontSize: 11 }}>
										{new Intl.NumberFormat('es-NI', {
											style: 'currency',
											currency: 'NIO'
										}).format(totalCobradoConsol)}
									</Typography>
								</Stack>
							</Stack>
							<hr />
						</Stack>
					)}
				</Container>
			</Dialog>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintReport
					ref={compRef}
					titulo={'Reporte de Recuperación'}
					fecha={`Fecha: ${moment().format('L')}`}
				>
					<Container maxWidth={false} sx={{ textAlign: 'center' }}>
						<Stack direction={'row'} justifyContent="space-between">
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Parametros:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Gestor Seleccionado: ${
										selectedGestor === 't' ? 'Todos' : gestorName
									}`}
								</Typography>

								<Typography fontSize={11} textAlign="left">
									{`Desde: ${moment(fechaDesde).format('L')} - Hasta:  ${moment(
										fechaHasta
									).format('L')}`}
								</Typography>
							</Stack>
							<Stack>
								<Typography
									sx={{
										color: '#2196f3',
										textAlign: 'left'
									}}
									fontSize={11}
								>
									Usuario:
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{user}
								</Typography>
								<Typography fontSize={11} textAlign="left">
									{`Fecha y Hora: ${moment().format('DD/MM/YYYY hh:mm A')}`}
								</Typography>
							</Stack>
						</Stack>

						<Container maxWidth={false} sx={{ textAlign: 'center' }}>
							<hr />
							{isEmpty(data) ? (
								<NoData />
							) : (
								<>
									{gestorList.map((gestor) => {
										const cGestor = data.filter((c) => c.gestor === gestor.id);
										let totalCap = 0;
										let totalIC = 0;
										let totalCobrado = 0;

										let totaldia = 0;
										let totalMora = 0;
										let totalVencido = 0;

										cGestor.map((c) => {
											totalCap += c.aCapital;
											totalIC += c.aIntCorriente;
											totalCobrado += c.totalRecibido;

											totaldia += c.dia;
											totalMora += c.mora;
											totalVencido += c.vencido;
										});

										return (
											<Stack key={gestor.id}>
												<Stack direction="row" spacing={1}>
													<Typography
														sx={{
															color: '#2196f3',
															textAlign: 'left'
														}}
														fontSize={11}
													>
														Gestor:
													</Typography>
													<Typography
														sx={{
															textAlign: 'left'
														}}
														fontSize={11}
													>
														{gestor.fullName}
													</Typography>
												</Stack>

												<Divider />

												{isEmpty(cGestor) ? (
													<>
														<Typography
															sx={{
																color: '#2196f3',
																textAlign: 'center',
																fontWeight: 'bold'
															}}
															fontSize={15}
														>
															Gestor no ha hecho ningun cobro!
														</Typography>
														<hr />
													</>
												) : (
													<>
														<TableContainer>
															<Table
																aria-label="simple table"
																size="small"
															>
																<TableHead>
																	<TableRow>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 11 }}
																		>
																			Transacción
																		</TableCell>
																		<TableCell
																			align="left"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			Cliente
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			#Crédito
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			F.Pago
																		</TableCell>

																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			A Capital
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			A.Int.Cnte
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			A Dia
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			A Mora
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			A Venc.
																		</TableCell>
																		<TableCell
																			align="center"
																			className="text-primary fw-bold"
																			style={{ fontSize: 10 }}
																		>
																			T. Recibido
																		</TableCell>
																	</TableRow>
																</TableHead>
																<TableBody>
																	{cGestor.map((row) => {
																		return (
																			<TableRow key={row.id}>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{row.id}
																				</TableCell>
																				<TableCell
																					align="left"
																					component="th"
																					scope="row"
																					className="text-dark"
																					style={{
																						fontSize: 9
																					}}
																				>
																					{row.fullName}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					className="text-dark"
																					style={{
																						fontSize: 9
																					}}
																				>
																					{row.creditId}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					className="text-dark"
																					style={{
																						fontSize: 9
																					}}
																				>
																					{moment(
																						row.fechaPago
																					).format('L')}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					className="text-dark"
																					style={{
																						fontSize: 9
																					}}
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.aCapital
																					)}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.aIntCorriente
																					)}
																				</TableCell>

																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.dia
																					)}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.mora
																					)}
																				</TableCell>
																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.vencido
																					)}
																				</TableCell>

																				<TableCell
																					align="center"
																					component="th"
																					scope="row"
																					style={{
																						fontSize: 9
																					}}
																					className="text-dark"
																				>
																					{new Intl.NumberFormat(
																						'es-NI',
																						{
																							style: 'currency',
																							currency:
																								'NIO'
																						}
																					).format(
																						row.totalRecibido
																					)}
																				</TableCell>
																			</TableRow>
																		);
																	})}
																</TableBody>
															</Table>
														</TableContainer>

														<hr />
														<Stack
															direction="row"
															flex="row"
															justifyContent="space-around"
														>
															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Cobros
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{cGestor.length}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total A Capital
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalCap)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total A Int. Cnte.
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalIC)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Recuperacion Dia
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totaldia)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Recuperacion Mora
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalMora)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Recuperacion Venvido
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalVencido)}
																</Typography>
															</Stack>

															<Stack textAlign="center">
																<Typography
																	style={{
																		fontWeight: 'bold',
																		color: '#03a9f4',
																		fontSize: 11
																	}}
																>
																	Total Cobrado
																</Typography>
																<Typography
																	style={{ fontSize: 11 }}
																>
																	{new Intl.NumberFormat(
																		'es-NI',
																		{
																			style: 'currency',
																			currency: 'NIO'
																		}
																	).format(totalCobrado)}
																</Typography>
															</Stack>
														</Stack>
														<hr />
													</>
												)}
											</Stack>
										);
									})}
								</>
							)}

							{gestorList.length === 1 ? (
								<></>
							) : (
								<Stack>
									<Typography
										sx={{
											color: '#2196f3',
											textAlign: 'center'
										}}
									>
										Consolidado
									</Typography>

									<hr />

									<Stack direction="row" flex="row" justifyContent="space-around">
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Cobros
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{totalCobrosConsol}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total A Capital
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalCapConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total A Int. Cnte.
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalICConsol)}
											</Typography>
										</Stack>

										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Rec Dia
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalDiaConsol)}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Rec Mora
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalMoraConsol)}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Rec Vencido
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalVencidoConsol)}
											</Typography>
										</Stack>
										<Stack textAlign="center">
											<Typography
												style={{
													fontWeight: 'bold',
													color: '#03a9f4',
													fontSize: 11
												}}
											>
												Total Cobrado
											</Typography>
											<Typography style={{ fontSize: 11 }}>
												{new Intl.NumberFormat('es-NI', {
													style: 'currency',
													currency: 'NIO'
												}).format(totalCobradoConsol)}
											</Typography>
										</Stack>
									</Stack>
									<hr />
								</Stack>
							)}
						</Container>
					</Container>
				</PrintReport>
			</div>
		</div>
	);
};

export default Recuperacion;

import React, { useContext, useRef } from 'react';

import { Typography, IconButton, Stack, Paper } from '@mui/material';
import moment from 'moment';

import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import { DataContext } from '../../../../context/DataContext';
import { conv2Letras } from '../../../../helpers/n2Letras';
import PrintBill from './PrintBill';
const DetalleAbono = ({ setShowModal, isDolar }) => {
	const { title, slogan, icon, dataAbono } = useContext(DataContext);
	const {
		id,
		client,
		concepto,
		cuotaDia,
		diasMora,
		fechaPago,
		montoAtrasado,
		montoCancelacion,
		nuevoSaldo,
		numeroAbono,
		realizadoPor,
    phone,
		totalAbonado,
		quote
	} = dataAbono;
	

	let ruc = '0810306930015Q';
	const compRef = useRef();

	const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { margin: 5mm; }
`;

	return (
		<div>
			<Paper
				style={{
					padding: 20,
					textAlign: 'center',
					width: 550
				}}
			>
				<Stack direction={'row'} justifyContent="space-between" alignItems={'start'}>
					<img src={icon} style={{ width: 50, height: 50 }} />

					<Stack>
						<Typography variant="h5" textAlign={'center'}>
							{title}
						</Typography>
						<Typography textAlign={'center'}>{slogan}</Typography>
					</Stack>

					<Stack>
						<ReactToPrint
							trigger={() => {
								return (
									<IconButton variant="outlined">
										<PrintRoundedIcon
											style={{ fontSize: 50, color: '#2979ff', width: 50 }}
										/>
									</IconButton>
								);
							}}
							content={() => compRef.current}
							pageStyle={pageStyle}
						/>
					</Stack>
				</Stack>

				<Stack>
					<Typography variant="h6" textAlign={'center'}>
						{`RUC - ${ruc}`}
					</Typography>
					<Typography textAlign={'center'}>{`Telefono - ${phone}`}</Typography>
					<Typography textAlign={'center'}>{`SUCURSAL - SOMOTILLO`}</Typography>
					<Typography textAlign={'center'}>
						{`Cobro del Dia # - ${numeroAbono.toString()}`}
					</Typography>

					<hr />
				</Stack>

				<Stack>
					<Typography variant="h6" textAlign={'center'}>
						{`Transaccion - ${quote.id}`}
					</Typography>
					<Typography textAlign={'center'}>{`Fecha / hora - ${moment(fechaPago).format(
						'D/MM/yyy hh:mm A'
					)}`}</Typography>
					<Typography textAlign={'center'}>{`CLIENTE: ${client}`}</Typography>

					<hr />
				</Stack>

				<Stack>
					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						// spacing={20}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Cuota dia`}
						</Typography>

						<Typography variant="h6" textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(cuotaDia)}`}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Monto Atrasado`}
						</Typography>

						<Typography variant="h6" textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoAtrasado)}`}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Dias Mora`}
						</Typography>

						<Typography variant="h6" textAlign="right">
							{diasMora.toString()}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Total Pagar`}
						</Typography>

						<Typography variant="h6" textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoAtrasado + cuotaDia)}`}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Monto Cancelacion`}
						</Typography>

						<Typography variant="h6" textAlign="right">
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(montoCancelacion)}`}
						</Typography>
					</Stack>

					<hr />
				</Stack>

				<Stack>
					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Total Abonado`}
						</Typography>

						<Typography variant="h6" textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(totalAbonado)}`}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Concepto`}
						</Typography>

						<Typography variant="h6" textAlign={'right'}>
							{concepto}
						</Typography>
					</Stack>

					<Stack
						direction={'row'}
						marginLeft={10}
						marginRight={10}
						alignItems={'start'}
						justifyContent={'space-between'}
					>
						<Typography variant="h6" textAlign={'left'}>
							{`Nuevo Saldo`}
						</Typography>

						<Typography variant="h6" textAlign={'right'}>
							{`${new Intl.NumberFormat('es-NI', {
								style: 'currency',
								currency: 'NIO'
							}).format(nuevoSaldo)}`}
						</Typography>
					</Stack>

					<hr />
					<Typography variant="h6" textAlign={'center'}>
						{realizadoPor}
					</Typography>
				</Stack>

				{/* <Stack spacing={2}>
          <Typography textAlign={"right"}>
            FECHA:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {`${moment(fechaPago).format("dddd,")} ${moment(fechaPago).format(
                "D"
              )} de ${moment(fechaPago).format("MMMM")} de ${moment(
                fechaPago
              ).format("YYYY")}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            RECIBIMOS DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${client}`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            LA CANTIDAD DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${conv2Letras(
                totalAbonado,
                isDolar
              )}  -  (${new Intl.NumberFormat(isDolar ? "en-US" : "es-NI", {
                style: "currency",
                currency: isDolar ? "USD" : "NIO",
              }).format(totalAbonado)})`}
            </Typography>
          </Typography>

          <Typography textAlign={"left"}>
            EN CONCEOTO DE:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${concepto}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" marginTop={5} spacing={3}>
          <Typography textAlign={"center"}>
            Firma:_______________________
          </Typography>
          <Typography textAlign={"left"}>
            Contrato:
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: "underline",
                textUnderlineOffset: 8,
              }}
              display="inline"
            >
              {` ${quote.credit.creditNumber}`}
            </Typography>
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between" marginTop={10}>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>ENTREGUE CONFORME</Typography>
          </Stack>
          <Stack>
            <Typography textAlign={"center"}>
              --------------------------------------------
            </Typography>
            <Typography textAlign={"center"}>RECIBI CONFORME</Typography>
          </Stack>
        </Stack> */}
			</Paper>

			<div
				style={{
					display: 'none'
				}}
			>
				<PrintBill ref={compRef} isDolar={isDolar} />
			</div>
		</div>
	);
};

export default DetalleAbono;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	TextField,
	Grid
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../../../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../../../helpers/Helpers';
import { getToken } from '../../../../../api/Account';

import {
	addArqueoAsync,
	getArqueoByIdAsync,
	getDenominationsCordobaAsync,
	getDenominationsDolarAsync
} from '../../../../../api/ArqueoCaja';
import CashRegisterAddDetail from './CashRegisterAddDetail';
import moment from 'moment';

const CashRegisterDetail = () => {
	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	let ruta = getRuta();
	const { id } = useParams();
    let navigate = useNavigate();
	const token = getToken();

	useEffect(() => {
		getdata();
	}, []);

	const getdata = async () => {
		setIsLoading(true);
		const result = await getArqueoByIdAsync(token, id);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		// setGastosOperativos(result.data.result);
		console.log(result.data.result);
		setIsLoading(false);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/cashregister`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h5" fontWeight={'bold'}>
							Detalle Arqueo
						</Typography>
					</Stack>
				</Paper>
			</Container>
		</div>
	);
};

export default CashRegisterDetail;

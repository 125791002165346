import React, { useState, useContext, useEffect } from 'react';
import {
	Typography,
	Container,
	Button,
	Paper,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from '@mui/material';
import { DataContext } from '../../../context/DataContext';
import { simpleMessage } from '../../../helpers/Helpers';
import { deleteToken, deleteUserData, getToken } from '../../../api/Account';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { getGestoresAsync, migrateWalletAsync } from '../../../api/Users';

const MigrateWallet = ({ setShowModal, localReload, setLocalReload }) => {
	const [gestorList, setGestorList] = useState([]);
	const [selectedGestor, setSelectedGestor] = useState('');

	const [gestorListDestino, setGestorListDestino] = useState([]);
	const [selectedGestorDestino, setSelectedGestorDestino] = useState('');

	const token = getToken();
	const { reload, setReload, setIsLoading, setIsLogged, setIsUnautorized } =
		useContext(DataContext);

	const getGestoresBySucursal = async () => {
		const result = await getGestoresAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setGestorList(result.data.result);
	};

	useEffect(() => {
		getGestoresBySucursal();
	}, []);

	const onchangeGestorBase = (value) => {
		setSelectedGestor(value);
		setSelectedGestorDestino('');
		setGestorListDestino([]);

		const filtered = gestorList.filter((p) => p.id !== value);
		setGestorListDestino(filtered);
	};

	const saveChangesAsync = async () => {
		Swal.fire({
			title: '¿Estas Seguro?',
			text: 'No podras revertir esto...!',
			icon: 'warning',
			showCancelButton: true,

			confirmButtonColor: '#2979ff',
			cancelButtonColor: '#f50057',

			confirmButtonText: 'Migrar!',
			cancelButtonText: 'Cancelar',
			customClass: {
				container: 'my-swal',
				popup: 'border'
			}
		}).then((result) => {
			if (result.isConfirmed) {
				if (validate()) {
					const data = {
						idGestorBase: selectedGestor,
						idGestorDestino: selectedGestorDestino
					};
					(async () => {
						setIsLoading(true);
						const result = await migrateWalletAsync(token, data);
						if (!result.statusResponse) {
							setIsLoading(false);
							if (result.error === 'Unauthorized') {
								setIsUnautorized(true);
								return;
							}
							simpleMessage(result.error, 'error');
							return;
						}

						setIsLoading(false);
						setLocalReload(!localReload);
						Swal.fire({
							title: 'Exito!',
							text: 'Migración exitosa.',
							icon: 'success',
							customClass: { container: 'my-swal', popup: 'border' }
						});

						setShowModal(false);
					})();
				}
			}
		});
	};

	const validate = () => {
		let isValid = true;
		if (selectedGestor.length === 0) {
			simpleMessage('Seleccione un gestor base', 'error');
			return (isValid = false);
		}

		if (selectedGestorDestino.length === 0) {
			simpleMessage('Seleccione un gestor destino', 'error');
			return (isValid = false);
		}

		if (selectedGestor === selectedGestorDestino) {
			simpleMessage('Debe seleccionar gestores diferentes', 'error');
			return (isValid = false);
		}
		return isValid;
	};

	return (
		<div>
			<Container style={{ width: 450 }}>
				<Paper
					style={{
						padding: 10
					}}
				>
					<Typography variant="h6" color={'#2979ff'} style={{ textAlign: 'center' }}>
						Gestor Base
					</Typography>

					<FormControl fullWidth style={{ textAlign: 'left' }}>
						<InputLabel id="demo-simple-select-standard-label">Gestor Base</InputLabel>
						<Select
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							onChange={(e) => onchangeGestorBase(e.target.value)}
							value={selectedGestor}
							label="Gestor Base"
							style={{ textAlign: 'left' }}
						>
							<MenuItem value="">
								<em>Seleccione un Gestor</em>
							</MenuItem>
							{gestorList.map((item) => {
								return (
									<MenuItem key={item.id} value={item.id}>
										{item.fullName}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</Paper>

				{selectedGestor.length === 0 ? (
					<></>
				) : (
					<>
						<hr />
						<Paper
							style={{
								padding: 10
							}}
						>
							<Typography
								variant="h6"
								color={'#2979ff'}
								style={{ textAlign: 'center' }}
							>
								Gestor Destino
							</Typography>

							<FormControl fullWidth style={{ textAlign: 'left' }}>
								<InputLabel id="demo-simple-select-standard-label">
									Gestor Destino
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									onChange={(e) => setSelectedGestorDestino(e.target.value)}
									value={selectedGestorDestino}
									label="Gestor Destino"
									style={{ textAlign: 'left' }}
								>
									<MenuItem value="">
										<em>Seleccione un Gestor</em>
									</MenuItem>
									{gestorListDestino.map((item) => {
										return (
											<MenuItem key={item.id} value={item.id}>
												{item.fullName}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
						</Paper>
					</>
				)}

				{selectedGestorDestino.length === 0 ? (
					<></>
				) : (
					<Button
						variant="outlined"
						startIcon={<FontAwesomeIcon icon={faSave} style={{ marginRight: 20 }} />}
						fullWidth
						onClick={() => saveChangesAsync()}
						style={{
							color: '#ffc400',
							borderColor: '#ffc400',
							marginTop: 20
						}}
						size="large"
					>
						Migrar Cartera
					</Button>
				)}
			</Container>
		</div>
	);
};

export default MigrateWallet;

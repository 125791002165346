import React from "react";
import { Container, Paper, Typography, Stack, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarWeek,
  faChartColumn,
  faLocationDot,
  faMoneyBillTrendUp,
  faMoneyBillWheat,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";
import HomeInfo from "./HomeInfo";
import ChartDia from "./homeInfoComponents/ChartDia";

import MoraByGestor from "./homeInfoComponents/MoraByGestor";
import ClientsByLocation from "./clientComponents/ClientsByLocation";
import CreditsByPeriodicidad from "./clientComponents/CreditsByPeriodicidad";
import ColocacionSemanal from "./homeInfoComponents/ColocacionSemanal";

const HomeContainer = () => {
  return (
    <div>
      <Container maxWidth="xl">
        <Paper
          style={{
            padding: 20,
            textAlign: "center",
          }}
        >
          <Stack direction={"row"}>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
            >
              <FontAwesomeIcon
                icon={faChartColumn}
                style={{ marginRight: 20 }}
                className="fa-beat"
              />
            </Typography>
            <Typography
              color={"#00a152"}
              textAlign="left"
              variant="h4"
              fontWeight={"bold"}
              className="d-none d-sm-block"
            >
              Resumen General
            </Typography>
          </Stack>

          <hr />
          <HomeInfo />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack direction={"row"} sx={{ mt: 2 }}>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                >
                  <FontAwesomeIcon
                    icon={faCalendarWeek}
                    style={{ marginRight: 20 }}
                    className="fa-beat"
                  />
                </Typography>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                  className="d-none d-sm-block"
                >
                  Grupo Periodicidad
                </Typography>
              </Stack>

              <hr />
              <Paper style={{ padding: 20 }}>
                <CreditsByPeriodicidad />
              </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
              <Stack direction={"row"} sx={{ mt: 2 }}>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                >
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    style={{ marginRight: 20 }}
                    className="fa-beat"
                  />
                </Typography>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                  className="d-none d-sm-block"
                >
                  Grupo Municipios
                </Typography>
              </Stack>

              <hr />
              <Paper style={{ padding: 20 }}>
                <ClientsByLocation />
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Stack direction={"row"} sx={{ mt: 2 }}>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                >
                  <FontAwesomeIcon
                    icon={faSackDollar}
                    style={{ marginRight: 20 }}
                    className="fa-beat"
                  />
                </Typography>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                  className="d-none d-sm-block"
                >
                  Colocación Semanal
                </Typography>
              </Stack>

              <hr />
              <ColocacionSemanal />
            </Grid>

            <Grid item xs={12}>
              <Stack direction={"row"} sx={{ mt: 2 }}>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBillTrendUp}
                    style={{ marginRight: 20 }}
                    className="fa-beat"
                  />
                </Typography>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                  className="d-none d-sm-block"
                >
                  Recupeación Diaria
                </Typography>
              </Stack>

              <hr />
              <ChartDia />
            </Grid>

            <Grid item xs={12}>
              <Stack direction={"row"} sx={{ mt: 2 }}>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBillWheat}
                    style={{ marginRight: 20 }}
                    className="fa-beat"
                  />
                </Typography>
                <Typography
                  color={"#00a152"}
                  textAlign="left"
                  variant="h4"
                  fontWeight={"bold"}
                  className="d-none d-sm-block"
                >
                  Mora por Gestor
                </Typography>
              </Stack>

              <hr />
              <MoraByGestor />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
};

export default HomeContainer;

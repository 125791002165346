import React, { useState, useContext } from 'react';
import {
	Grid,
	Paper,
	TextField,
	Typography,
	Button,
	IconButton,
	InputAdornment,
	Stack
} from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { size } from 'lodash';
import Loading from '../components/Loading';
import { DataContext } from '../context/DataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faUser } from '@fortawesome/free-solid-svg-icons';
import { simpleMessage } from '../helpers/Helpers';
import { apiLogin } from '../api/Account';

function Copyright(props) {
	return (
		<Typography variant="body2" color="text.secondary" align="center" {...props}>
			{`Copyright © ${props.title} ${new Date().getFullYear()}.`}
		</Typography>
	);
}

const Login = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [userName, setUserName] = useState('');
	const [pass, setPass] = useState('');

	const {
		setIsLoading,
		setIsDarkMode,
		setIsLogged,
		setAccess,
		title,
		slogan,
		icon,
		setIsServerOff
	} = useContext(DataContext);

	const validar = (e) => {
		let isValid = true;
		if (!userName.trim()) {
			isValid = false;
			simpleMessage('Debe ingresar su usuario', 'error');
			return;
		}

		if (!pass.trim()) {
			isValid = false;
			simpleMessage('Debe ingresar su contraseña', 'error');
			return;
		}

		if (size(pass) < 6) {
			isValid = false;
			simpleMessage('la contraseña es menor de 6 caracteres', 'error');
			return;
		}
		return isValid;
	};

	const iniciarSesion = async (event) => {
    event.preventDefault();
		if (!validar()) {
			return;
		}
		setIsLoading(true);
		const data = {
			userName: userName,
			password: pass
		};
		const result = await apiLogin(data);
		if (!result.statusResponse) {
			simpleMessage(result.error, 'error');
			setIsLoading(false);
			return;
		}

		setIsLoading(false);
		setIsServerOff(result.data.userRol.isOff);
		setIsDarkMode(result.data.isDarkMode);
		setAccess(result.data.userRol.permissions);
		setIsLogged(true);
		setUserName('');
		setPass('');
	};

	const tema = createTheme({
		palette: {
			mode: 'dark'
		},
		components: {
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 30
					}
				}
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 20
					}
				}
			}
		}
	});

	return (
		<div>
			<ThemeProvider theme={tema}>
				<header className="header-login container-website">
					<div className="row">
						<div className="block-logo fx fx-ai-c">
							<img alt="logo" src={icon} />
							<Stack>
								<h1 className="h3-txt-login">{title}</h1>
								<Typography textAlign={'center'} className="h3-txt-login">
									{slogan}
								</Typography>
							</Stack>
						</div>
					</div>
				</header>

				<div
					className="section-banner-login fx fx-ai-c"
					style={{
						paddingTop: 70,
						paddingBottom: 40
					}}
				>
					<div
						className="container-website"
						style={{
							paddingTop: 24,
							paddingBottom: 24
						}}
					>
						<Paper className="content-block" style={{ padding: 20 }}>
							<Grid align="center">
								<Typography variant="h4">Iniciar Sesión</Typography>
							</Grid>

							<Grid component={'form'} container onSubmit={iniciarSesion}>
								<TextField
									fullWidth
									onChange={(e) => setUserName(e.target.value)}
									value={userName}
									label="Usuario"
									placeholder="Ingrese su usuario"
									InputProps={{
										style: { fontFamily: 'nunito', color: 'white' },
										startAdornment: (
											<InputAdornment position="start">
												<FontAwesomeIcon
													icon={faUser}
													style={{ color: '#c4c4c4' }}
												/>
											</InputAdornment>
										)
									}}
								/>

								<TextField
									fullWidth
									style={{ marginTop: 10 }}
									type={showPassword ? 'text' : 'password'}
									onChange={(e) => setPass(e.target.value)}
									value={pass}
									label="Contraseña"
									placeholder="Ingrese su contraseña"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start"></InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="start">
												<IconButton
													onClick={() => setShowPassword(!showPassword)}
													aria-label="toggle password visivility"
												>
													{showPassword ? (
														<FontAwesomeIcon
															icon={faEye}
															style={{ color: '#c4c4c4' }}
														/>
													) : (
														<FontAwesomeIcon
															icon={faEyeSlash}
															style={{ color: '#c4c4c4' }}
														/>
													)}
												</IconButton>
											</InputAdornment>
										)
									}}
								/>

								<Button
									variant="outlined"
									style={{ marginTop: 20, padding: 10 }}
									color="primary"
									fullWidth
									type="submit"
									// onClick={() => iniciarSesion()}
								>
									Iniciar Sesion
								</Button>
							</Grid>
							<Copyright sx={{ mt: 5 }} title={title} />
						</Paper>
					</div>
				</div>
			</ThemeProvider>
			<Loading />
		</div>
	);
};

export default Login;

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	Container,
	Paper,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	TextField,
	Grid
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faSave } from '@fortawesome/free-solid-svg-icons';
import { DataContext } from '../../../../../context/DataContext';
import { getRuta, simpleMessage } from '../../../../../helpers/Helpers';
import { getToken } from '../../../../../api/Account';

import {
	addArqueoAsync,
	getDenominationsCordobaAsync,
	getDenominationsDolarAsync,
	getMontoRecuperacionAsync
} from '../../../../../api/ArqueoCaja';
import CashRegisterAddDetail from './CashRegisterAddDetail';
import moment from 'moment';

const CashregisterAdd = () => {
	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(date);

	const [recuperacion, setRecuperacion] = useState(0);
	const [otrosIngresos, setOtrosIngresos] = useState(0);
	const [denominationCordobaList, setDenominationCordobaList] = useState([]);
	const [denominationDolarList, setDenominationDolarList] = useState([]);

	const [detalleArqueoCordoba, setDetalleArqueoCordoba] = useState([]);
	const [detalleArqueoDolar, setDetalleArqueoDolar] = useState([]);
	const [totalBilletajeCorodba, setTotalBilletajeCordoba] = useState(0);
	const [totalBilletajeDolar, setTotalBilletajeDolar] = useState(0);
	const [totalEfectivo, setTotalEfectivo] = useState(0);
	const [diferencia, setDiferencia] = useState(0);
	const [tipoCambio, setTipoCambio] = useState(0);
	const [observaciones, setObservaciones] = useState('');

	const [desembolsos, setDesembolsos] = useState(0);
	const [gastosAdministrativos, setGastosAdministrativos] = useState(0);
	const [gastosOperativos, setGastosOperativos] = useState(0);
	const [otrosGastos, setOtrosGastos] = useState(0);
	const [saldoFinal, setSaldoFinal] = useState(0);

	const [reloadArqueo, setReloadArqueo] = useState(false);
	const { setIsLoading, setIsUnautorized } = useContext(DataContext);

	const token = getToken();
	let ruta = getRuta();
	let navigate = useNavigate();

	useEffect(() => {
		getDenominacionCordoba();
		getDenominacionDolar();
		getRecuperacion();
	}, []);

	useEffect(() => {}, [reloadArqueo]);

	const getDenominacionCordoba = async () => {
		setIsLoading(true);
		const result = await getDenominationsCordobaAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setDenominationCordobaList(result.data.result);
		objectDetalleCordoba(result.data.result);
	};

	const objectDetalleCordoba = (data) => {
		let exp = [];
		data.map((item) => {
			let obj = {
				id: item.id,
				description: item.description,
				valor: item.valor,
				cantidad: '',
				total: 0
			};
			exp.push(obj);
		});
		setDetalleArqueoCordoba(exp);
	};

	const getDenominacionDolar = async () => {
		setIsLoading(true);
		const result = await getDenominationsDolarAsync(token);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setDenominationDolarList(result.data.result);
		objectDetalleDolar(result.data.result);
	};

	const objectDetalleDolar = (data) => {
		let exp = [];
		data.map((item) => {
			let obj = {
				id: item.id,
				description: item.description,
				valor: item.valor,
				cantidad: '',
				total: 0
			};
			exp.push(obj);
		});
		setDetalleArqueoDolar(exp);
	};

	const onEditDetalleCordoba = (id, valor, cantidad) => {
		if (/^\d*\.?\d*$/.test(cantidad.toString()) || cantidad === '') {
			let total = 0;
			let data = detalleArqueoCordoba;
			data.map((item) => {
				if (item.id === id) {
					item.cantidad = cantidad;
					item.total = valor * cantidad;
				}
				total += item.total;
			});
			setTotalBilletajeCordoba(total);
			let conv = parseFloat(totalBilletajeDolar) * parseFloat(tipoCambio);
			setTotalEfectivo(total + conv);
			setDiferencia(recuperacion - total - conv);
			setReloadArqueo(!reloadArqueo);
		}
	};

	const onEditDetalleDolar = (id, valor, cantidad) => {
		if (tipoCambio === '' || parseFloat(tipoCambio) <= 0) {
			simpleMessage('Ingrese el tipo de Cambio', 'error');
			return;
		}
		if (/^\d*\.?\d*$/.test(cantidad.toString()) || cantidad === '') {
			let total = 0;
			let data = detalleArqueoDolar;
			data.map((item) => {
				if (item.id === id) {
					item.cantidad = cantidad;
					item.total = valor * cantidad;
				}
				total += item.total;
			});
			setTotalBilletajeDolar(total);
			let conv = parseFloat(total) * parseFloat(tipoCambio);
			setTotalEfectivo(totalBilletajeCorodba + conv);
			setDiferencia(recuperacion - conv - totalBilletajeCorodba);
			setReloadArqueo(!reloadArqueo);
		}
	};

	const saveArqueo = async () => {
		let modelDetalleArqueoCordoba = [];
		let modelDetalleArqueoDolar = [];

		detalleArqueoCordoba.map((item) => {
			let model = {
				demonimacionId: item.id,
				cantidad: item.cantidad === '' ? 0 : item.cantidad,
				total: item.total
			};
			modelDetalleArqueoCordoba.push(model);
		});

		detalleArqueoDolar.map((item) => {
			let model = {
				demonimacionId: item.id,
				cantidad: item.cantidad === '' ? 0 : item.cantidad,
				total: item.total
			};
			modelDetalleArqueoDolar.push(model);
		});

		const data = {
			fecha: moment(fechaDesde).format('YYYY-MM-DD'),
			recuperacion,
			montoOtrosIngresos: otrosIngresos,
			denominacionCordobas: modelDetalleArqueoCordoba,
			denominacionDolars: modelDetalleArqueoDolar,
			efectivoCordoba: totalBilletajeCorodba,
			tipoCambio,
			efectivoDolar: totalBilletajeDolar,
			totalEfectivo,
			diferencia,
			montoDesembolsos: desembolsos,
			montoGastosAdministrativos: gastosAdministrativos,
			montoGastosOperativos: gastosOperativos,
			montoOtrosGastos: otrosGastos,
			saldoFinal,
			observaciones
		};

		setIsLoading(true);
		const result = await addArqueoAsync(token, data);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}

		setTotalBilletajeCordoba(0);
		setTotalBilletajeDolar(0);
		setDiferencia(0);
		objectDetalleCordoba(denominationCordobaList);
		objectDetalleDolar(denominationDolarList);
		setIsLoading(false);
		navigate(`${ruta}/cashregister`);
	};

	const onChangeTipoCambio = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			let newValue = 0;
			if (value !== '') {
				newValue = parseFloat(value);
			}
			setTipoCambio(value);

			setTotalEfectivo(totalBilletajeCorodba + totalBilletajeDolar * parseFloat(newValue));
			setDiferencia(recuperacion - parseFloat(totalBilletajeDolar) * parseFloat(newValue));
			return;
		}
	};

	const onChangeOtrosIngresos = (value) => {
		if (/^\d*\.?\d*$/.test(value.toString()) || value === '') {
			setOtrosIngresos(value);
			return;
		}
	};

	const getRecuperacion = async () => {
		setIsLoading(true);
		const result = await getMontoRecuperacionAsync(
			token,
			moment(fechaDesde).format('YYYY-MM-DD')
		);
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setRecuperacion(result.data.result);
		setDiferencia(parseFloat(result.data.result) - totalEfectivo);

		setIsLoading(false);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/cashregister`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h5" fontWeight={'bold'}>
							Agregar Arqueo
						</Typography>
					</Stack>

					<hr />
					<Grid container spacing={2}>
						<Grid item sm={12} md={5}>
							<CashRegisterAddDetail
								fechaDesde={fechaDesde}
								setFechaDesde={setFechaDesde}
								recuperacion={recuperacion}
								saveArqueo={saveArqueo}
								reloadArqueo={reloadArqueo}
								setReloadArqueo={setReloadArqueo}
								gastosAdministrativos={gastosAdministrativos}
								setGastosAdministrativos={setGastosAdministrativos}
								gastosOperativos={gastosOperativos}
								setGastosOperativos={setGastosOperativos}
								otrosGastos={otrosGastos}
								setOtrosGastos={setOtrosGastos}
								otrosIngresos={otrosIngresos}
								setOtrosIngresos={setOtrosIngresos}
								setSaldoFinal={setSaldoFinal}
								setTotalEfectivo={setTotalEfectivo}
								totalEfectivo={totalEfectivo}
								desembolsos={desembolsos}
								setDesembolsos={setDesembolsos}
								getRecuperacion={getRecuperacion}
							/>
						</Grid>

						<Grid item sm={12} md={7}>
							<Paper
								style={{
									padding: 20
								}}
							>
								<Typography color={'#00a152'} fontWeight={'bold'}>
									Total Efectivo
								</Typography>

								<hr />
								<Grid container spacing={2}>
									<Grid item sm={12} md={6}>
										<Paper
											style={{
												padding: 20
											}}
										>
											<Typography
												color={'#00a152'}
												fontWeight={'bold'}
												marginBottom={4}
											>
												{`Billetaje Cordoba:  ${new Intl.NumberFormat(
													'es-NI',
													{
														style: 'currency',
														currency: 'NIO'
													}
												).format(totalBilletajeCorodba)} `}
											</Typography>

											<hr />
											<TableContainer>
												<Table aria-label="simple table" size="small">
													<TableHead>
														<TableRow>
															<TableCell
																align="left"
																className="text-primary fw-bold"
																width={150}
															>
																Denominacion
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																width={150}
															>
																Billetaje
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
															>
																C$
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{detalleArqueoCordoba.map((row) => {
															const {
																id,
																description,
																valor,
																cantidad,
																total
															} = row;
															return (
																<TableRow key={id}>
																	<TableCell
																		align="left"
																		width={150}
																	>
																		{description}
																	</TableCell>
																	<TableCell
																		align="center"
																		width={150}
																	>
																		<TextField
																			size="small"
																			value={cantidad}
																			fullWidth
																			onChange={(e) =>
																				onEditDetalleCordoba(
																					id,
																					valor,
																					e.target.value
																				)
																			}
																		/>
																	</TableCell>
																	<TableCell align="center">
																		<Typography>
																			{total}
																		</Typography>
																	</TableCell>
																</TableRow>
															);
														})}
													</TableBody>
												</Table>
											</TableContainer>
										</Paper>
									</Grid>
									<Grid item sm={12} md={6}>
										<Paper
											style={{
												padding: 20
											}}
										>
											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												alignItems="center"
												spacing={1}
												justifyContent={'space-between'}
											>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{`Billetaje Dolar:  ${new Intl.NumberFormat(
														'es-NI',
														{
															style: 'currency',
															currency: 'NIO'
														}
													).format(totalBilletajeDolar)} `}
												</Typography>

												<TextField
													size="small"
													style={{ width: 150 }}
													onChange={(e) =>
														onChangeTipoCambio(e.target.value)
													}
													label={'Tipo de cambio'}
													value={tipoCambio}
												/>
											</Stack>

											<hr />
											<TableContainer>
												<Table aria-label="simple table" size="small">
													<TableHead>
														<TableRow>
															<TableCell
																align="left"
																className="text-primary fw-bold"
																width={150}
															>
																Denominacion
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
																width={150}
															>
																Billetaje
															</TableCell>
															<TableCell
																align="center"
																className="text-primary fw-bold"
															>
																C$
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{detalleArqueoDolar.map((row) => {
															const {
																id,
																description,
																valor,
																cantidad,
																total
															} = row;
															return (
																<TableRow key={id}>
																	<TableCell
																		align="left"
																		width={150}
																	>
																		{description}
																	</TableCell>
																	<TableCell
																		align="center"
																		width={150}
																	>
																		<TextField
																			size="small"
																			value={cantidad}
																			fullWidth
																			onChange={(e) =>
																				onEditDetalleDolar(
																					id,
																					valor,
																					e.target.value
																				)
																			}
																		/>
																	</TableCell>
																	<TableCell align="center">
																		<Typography>
																			{total}
																		</Typography>
																	</TableCell>
																</TableRow>
															);
														})}
													</TableBody>
												</Table>
											</TableContainer>
										</Paper>

										<Paper
											style={{
												padding: 20,
												marginTop: 10
											}}
										>
											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												alignItems="center"
												spacing={1}
												justifyContent={'space-between'}
											>
												<Typography fontWeight={'bold'}>
													Recuperacion Sistema
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(recuperacion)}
												</Typography>
											</Stack>

											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												alignItems="center"
												spacing={1}
												justifyContent={'space-between'}
											>
												<Typography fontWeight={'bold'}>
													Total Billetaje
												</Typography>
												<Typography color={'#00a152'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(totalEfectivo)}
												</Typography>
											</Stack>

											<hr />

											<Stack
												direction={{ xs: 'column', sm: 'row' }}
												alignItems="center"
												spacing={1}
												justifyContent={'space-between'}
											>
												<Typography fontWeight={'bold'}>
													Diferencia
												</Typography>
												<Typography color={'#ffc400'} fontWeight={'bold'}>
													{new Intl.NumberFormat('es-NI', {
														style: 'currency',
														currency: 'NIO'
													}).format(diferencia)}
												</Typography>
											</Stack>
										</Paper>
									</Grid>
								</Grid>

								<TextField
									style={{ marginTop: 10 }}
									fullWidth
									onChange={(e) => onChangeOtrosIngresos(e.target.value)}
									label={'Otros Ingresos'}
									value={otrosIngresos}
								/>

								<TextField
									style={{ marginTop: 10 }}
									fullWidth
									multiline
									onChange={(e) => setObservaciones(e.target.value.toUpperCase())}
									label={'Observaciones'}
									value={observaciones}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</div>
	);
};

export default CashregisterAdd;

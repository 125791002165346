import React, { useState, useEffect, useContext } from 'react';

import {
	Container,
	Paper,
	TableContainer,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Table,
	Typography,
	Stack,
	Button,
	TextField
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { DataContext } from '../../../context/DataContext';
import { getToken } from '../../../api/Account';
import { isAccess, simpleMessage } from '../../../helpers/Helpers';
import NoData from '../../../components/NoData';
import { getCajaMovAsync } from '../../../api/Cash';
import { PaginationComponent } from '../../../components/PaginationComponent';
import SmallModal from '../../../components/modals/SmallModal';
import AddCajaMovment from './AddCajaMovment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const Caja = () => {
	const [cajaMovList, setCajaMovList] = useState([]);
	const [localReload, setLocalReload] = useState(false);

	const [showModal, setShowModal] = useState(false);
	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
	const { setIsLoading, setIsUnautorized, access } = useContext(DataContext);
	const token = getToken();

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = cajaMovList.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	useEffect(() => {
		getData(fechaDesde);
	}, [localReload]);

	const addMovment = () => {
		// setIsEntrada(value);
		setShowModal(true);
	};

	const getData = async (desde) => {
		setIsLoading(true);
		const result = await getCajaMovAsync(token, moment(desde).format('YYYY-MM-DD'));
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);
		setCajaMovList(result.data.result);
	};

	const onChangeFecha = (desde) => {
		setFechaDesde(desde);
		getData(desde);
	};

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack
						direction={{ sm: 'column', md: 'row' }}
						justifyContent="space-between"
						spacing={2}
					>
						{/* <Stack spacing={2}> */}
						<Typography
							color={'#00a152'}
							variant="h4"
							fontWeight={'bold'}
							className="d-none d-sm-block"
						>
							Movimientos de Caja
						</Typography>
						{/* </Stack> */}

						{isAccess(access, 'CAJA CREATE') ? (
							<Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
								<DesktopDatePicker
									closeOnSelect
									label="Desde"
									inputFormat="DD/MM/YYYY"
									value={fechaDesde}
									onChange={(newValue) => onChangeFecha(newValue)}
									renderInput={(params) => <TextField {...params} />}
								/>
								<Button
									onClick={() => {
										addMovment(true);
									}}
									startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
									variant="outlined"
									style={{
										color: '#2979ff',
										borderColor: '#2979ff'
									}}
								>
									Agregar Movimiento
								</Button>
							</Stack>
						) : (
							<></>
						)}
					</Stack>

					<hr />

					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Fecha
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Concepto
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Entrada
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Salida
											</TableCell>

											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Realizado Por
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{currentItem.map((row) => {
											return (
												<TableRow key={row.id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{moment(row.fecha).format('L')}
													</TableCell>
													<TableCell align="left">
														{row.description}
													</TableCell>
													<TableCell align="center">
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.entradas)}
													</TableCell>
													<TableCell align="center">
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.salidas)}
													</TableCell>
													{/* <TableCell align="center">
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(row.saldo)}
													</TableCell> */}
													<TableCell align="left">
														{`${
															row.realizadoPor.fullName.split(' ')[0]
														}  ${
															row.realizadoPor.fullName.split(' ')[1]
														}`}
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<PaginationComponent
								data={cajaMovList}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>

			<SmallModal
				titulo={'Movimiento en Caja'}
				isVisible={showModal}
				setVisible={setShowModal}
			>
				<AddCajaMovment
					setShowModal={setShowModal}
					localReload={localReload}
					setLocalReload={setLocalReload}
				/>
			</SmallModal>
		</div>
	);
};

export default Caja;

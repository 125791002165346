import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Paper,
} from "@mui/material";
import { isEmpty } from "lodash";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import moment from "moment";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";
import { getToken, getUser } from "../../../api/Account";
import NoData from "../../../components/NoData";
import { getCierreCajaAsync } from "../../../api/Report";
import { simpleMessage } from "../../../helpers/Helpers";
import { PrintReport } from "../../../components/PrintReport";

const CierreCaja = () => {
  const [data, setData] = useState([]);

  const [sumDesembolsos, setSumDesembolsos] = useState(0);
  const [sumAbonoCajaChica, setSumAbonoCajaChica] = useState(0);
  const [sumGastosAdministrativos, setSumGastosAdministrativos] = useState(0);
  const [sumGastosOperativos, setSumGastosOperativos] = useState(0);
  const [sumAbonosAnulados, setSumAbonosAnulados] = useState(0);
  const [sumOtrosGastos, setSumOtrosGastos] = useState(0);

  const [sumRecuperacion, setSumRecuperacion] = useState(0);
  const [sumIngresoBancos, setSumIngresoBancos] = useState(0);
  const [sumOtrosIngresos, setSumOtrosIngresos] = useState(0);

  const [totalSalidas, setTotalSalidas] = useState(0);
  const [totalEntradas, setTotalEntradas] = useState(0);
  const [cierre, setCierre] = useState(0);

  const compRef = useRef();
  const { fecha } = useParams();
  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const token = getToken();
  const user = getUser();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const result = await getCierreCajaAsync(token, fecha.toString());
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
      setTotales(result.data.result);
    })();
  }, []);

  const setTotales = (data) => {
    let totalDesembolsos = 0;
    let totalAbonoCajaChica = 0;
    let totalGastosAdministrativos = 0;
    let totalGastosOperativos = 0;
    let totalAbonosAnulados = 0;
    let totalOtrosGastos = 0;

    let totalRecuperacion = 0;
    let totalIngresosBanco = 0;
    let totalOtrosIngresos = 0;

    data.desembolsos.map((item) => (totalDesembolsos += item.montoEntregado));
    setSumDesembolsos(totalDesembolsos);

    data.abonoCajaChica.map((item) => (totalAbonoCajaChica += item.salidas));
    setSumAbonoCajaChica(totalAbonoCajaChica);

    data.gastosAdministrativos.map(
      (item) => (totalGastosAdministrativos += item.entradas)
    );
    setSumGastosAdministrativos(totalGastosAdministrativos);

    data.gastosOperativos.map(
      (item) => (totalGastosOperativos += item.entradas)
    );
    setSumGastosOperativos(totalGastosOperativos);

    data.abonosAnulados.map(
      (item) => (totalAbonosAnulados += item.totalRecibido)
    );
    setSumAbonosAnulados(totalAbonosAnulados);

    data.otrosGastos.map((item) => (totalOtrosGastos += item.salidas));
    setSumOtrosGastos(totalOtrosGastos);

    //ingresos
    data.recuperacion.map((item) => (totalRecuperacion += item.totalRecibido));
    setSumRecuperacion(totalRecuperacion);
    data.ingresosBanco.map((item) => (totalIngresosBanco += item.entradas));
    setSumIngresoBancos(totalIngresosBanco);
    data.otrosIngresos.map((item) => (totalOtrosIngresos += item.entradas));
    setSumOtrosIngresos(totalOtrosIngresos);

    setTotalSalidas(
      totalDesembolsos +
        totalAbonoCajaChica +
        totalGastosAdministrativos +
        totalGastosOperativos +
        totalOtrosGastos
    );

    setTotalEntradas(
      totalRecuperacion + totalIngresosBanco + totalOtrosIngresos
    );

    setCierre(
      totalRecuperacion +
        totalIngresosBanco +
        totalOtrosIngresos -
        (totalDesembolsos +
          totalAbonoCajaChica +
          totalGastosAdministrativos +
          totalGastosOperativos +
          totalOtrosGastos)
    );
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
   @page { size: landscape;  margin: 8mm; }
`;

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {slogan}
          </Typography>
          <Typography
            sx={{
              color: "#2196f3",
              textAlign: "center",
              fontWeight: "bold",
            }}
            variant="h6"
            component="div"
          >
            Reporte Cierre de Caja
          </Typography>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  Fecha Seleccionada:
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {moment(fecha).format("L")}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>

          <ReactToPrint
            trigger={() => {
              return (
                <IconButton
                  variant="outlined"
                  style={{ position: "fixed", right: 20, top: 75 }}
                >
                  <PrintRoundedIcon
                    style={{ fontSize: 35, color: "#2979ff", width: 35 }}
                  />
                </IconButton>
              );
            }}
            content={() => compRef.current}
            pageStyle={pageStyle}
          />
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />

          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              <Paper
                elevation={10}
                style={{
                  padding: 20,
                }}
              >
                <Typography
                  sx={{
                    color: "#00a152",
                    textAlign: "center",
                    marginBottom: 1,
                  }}
                  fontSize={16}
                >
                  Consolidado:
                </Typography>

                <hr />

                <TableContainer>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Fecha
                        </TableCell>
                        <TableCell
                          align="Left"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Descripción
                        </TableCell>

                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Salida
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Entrada
                        </TableCell>
                        <TableCell
                          align="center"
                          className="text-primary fw-bold"
                          style={{ fontSize: 12 }}
                        >
                          Saldo
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          DESEMBOLSOS DEL DIA
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumDesembolsos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      {/* <TableRow key={2}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          TRASLADO DE EFECTIVO A CAJA CHICA
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumAbonoCajaChica)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow> */}

                      <TableRow key={4}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          GASTOS ADMINISTRATIVOS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumGastosAdministrativos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={5}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          GASTOS OPERATIVOS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumGastosOperativos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={6}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          OTROS GASTOS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#ffc400" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumOtrosGastos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={3}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          ANULACIONES DE ABONOS
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "gray" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumAbonosAnulados)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={7}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          RECUPERACION EFECTIVA DEL DIA
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#00a152" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumRecuperacion)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={8}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          INGRESOS PROVENIENTE DE BANCOS
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#00a152" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumIngresoBancos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={9}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 11 }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell align="left" style={{ fontSize: 11 }}>
                          OTROS INGRESOS
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontSize: 11, color: "#00a152" }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(sumOtrosIngresos)}
                        </TableCell>
                        <TableCell align="center" style={{ fontSize: 11 }}>
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(0)}
                        </TableCell>
                      </TableRow>

                      <TableRow key={10}>
                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          style={{ fontSize: 16, fontWeight: "bold" }}
                        >
                          {moment(fecha).format("L")}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ fontSize: 16, fontWeight: "bold" }}
                        >
                          CIERRE DE CAJA
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 16,
                            color: "#ffc400",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(totalSalidas)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 16,
                            color: "#00a152",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(totalEntradas)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontSize: 16,
                            color: "#2979ff",
                            fontWeight: "bold",
                          }}
                        >
                          {new Intl.NumberFormat("es-NI", {
                            style: "currency",
                            currency: "NIO",
                          }).format(cierre)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                <hr />
              </Paper>
            </>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte Cierre de Caja"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Stack direction={"row"} justifyContent="space-between">
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Parametros:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                Fecha Seleccionada:
              </Typography>

              <Typography fontSize={11} textAlign="left">
                {moment(fecha).format("L")}
              </Typography>
            </Stack>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "left",
                }}
                fontSize={11}
              >
                Usuario:
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {user}
              </Typography>
              <Typography fontSize={11} textAlign="left">
                {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
              </Typography>
            </Stack>
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <hr />

            {isEmpty(data) ? (
              <NoData />
            ) : (
              <>
                <div>
                  <Typography
                    sx={{
                      color: "#00a152",
                      textAlign: "center",
                      marginBottom: 1,
                    }}
                    fontSize={16}
                  >
                    Consolidado:
                  </Typography>

                  <TableContainer>
                    <Table aria-label="simple table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Fecha
                          </TableCell>
                          <TableCell
                            align="Left"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Descripción
                          </TableCell>

                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Salida
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Entrada
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-primary fw-bold"
                            style={{ fontSize: 12 }}
                          >
                            Saldo
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow key={1}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            className="text-dark"
                            style={{ fontSize: 11 }}
                          >
                            DESEMBOLSOS DEL DIA
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#ffc400" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumDesembolsos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-dark"
                            style={{ fontSize: 11 }}
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            className="text-dark"
                            style={{ fontSize: 11 }}
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        {/* <TableRow key={2}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            className="text-dark"
                            style={{ fontSize: 11 }}
                          >
                            TRASLADO DE EFECTIVO A CAJA CHICA
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#ffc400" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumAbonoCajaChica)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow> */}

                        <TableRow key={4}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            GASTOS ADMINISTRATIVOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#ffc400" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumGastosAdministrativos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={5}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            GASTOS OPERATIVOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#ffc400" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumGastosOperativos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={6}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            OTROS GASTOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#ffc400" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumOtrosGastos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={3}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            ANULACIONES DE ABONOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "gray" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumAbonosAnulados)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={7}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            RECUPERACION EFECTIVA DEL DIA
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#00a152" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumRecuperacion)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={8}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            INGRESOS PROVENIENTE DE BANCOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#00a152" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumIngresoBancos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={9}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            OTROS INGRESOS
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11, color: "#00a152" }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(sumOtrosIngresos)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontSize: 11 }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(0)}
                          </TableCell>
                        </TableRow>

                        <TableRow key={10}>
                          <TableCell
                            align="center"
                            component="th"
                            scope="row"
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            className="text-dark"
                          >
                            {moment(fecha).format("L")}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize: 16, fontWeight: "bold" }}
                            className="text-dark"
                          >
                            CIERRE DE CAJA
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 16,
                              color: "#ffc400",
                              fontWeight: "bold",
                            }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalSalidas)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 16,
                              color: "#00a152",
                              fontWeight: "bold",
                            }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(totalEntradas)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontSize: 16,
                              color: "#2979ff",
                              fontWeight: "bold",
                            }}
                            className="text-dark"
                          >
                            {new Intl.NumberFormat("es-NI", {
                              style: "currency",
                              currency: "NIO",
                            }).format(cierre)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <hr />
                </div>
              </>
            )}
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default CierreCaja;

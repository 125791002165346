import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
	Button,
	IconButton,
	Container,
	Paper,
	Tooltip,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronCircleLeft,
	faCirclePlus,
	faExternalLink,
	faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { DataContext } from '../../../context/DataContext';
import { getRuta, isAccess, simpleMessage } from '../../../helpers/Helpers';
import { deleteToken, deleteUserData, getToken } from '../../../api/Account';
import NoData from '../../../components/NoData';
import { getDepartmentByIdAsync } from '../../../api/Locations';
import { PaginationComponent } from '../../../components/PaginationComponent';
import SmallModal from '../../../components/modals/SmallModal';
import AddEditAbMun from './AddEditAbMun';

const Municipalities = () => {
	let ruta = getRuta();
	const { setIsLoading, reload, access, setIsUnautorized } = useContext(DataContext);
	const token = getToken();
	let navigate = useNavigate();
	const { id } = useParams();

	const [department, setDepartment] = useState([]);
	const [municipalityList, setMunicipalityList] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = municipalityList.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const [showModal, setShowModal] = useState(false);
	const [selectedMun, setSelectedMun] = useState('');

	useEffect(() => {
		const loadData = async () => {
			setIsLoading(true);

			const resultDepto = await getDepartmentByIdAsync(token, id);
			if (!resultDepto.statusResponse) {
				setIsLoading(false);
				if (resultDepto.error === 'Unauthorized') {
					setIsUnautorized(true);
					return;
				}
				simpleMessage(resultDepto.error, 'error');
				return;
			}

			setDepartment(resultDepto.data.result);
			setMunicipalityList(resultDepto.data.result.municipalities);
			setIsLoading(false);
		};
		loadData();
	}, [reload]);

	return (
		<div>
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" spacing={1}>
						<Button
							onClick={() => {
								navigate(`${ruta}/locations`);
							}}
							style={{
								color: '#2979ff',
								borderColor: '#2979ff'
							}}
							variant="outlined"
						>
							<FontAwesomeIcon
								style={{ marginRight: 10, fontSize: 20 }}
								icon={faChevronCircleLeft}
							/>
							Regresar
						</Button>

						<Typography color={'#00a152'} variant="h5" fontWeight={'bold'}>
							Detalle Depto:
						</Typography>

						<Typography variant="h5" fontWeight={'bold'}>
							{department.name}
						</Typography>
					</Stack>

					<hr />

					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="simple table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												#
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Municipio
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Abreviatura
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												# Comunidades
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Ver
											</TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										{currentItem.map((row) => {
											return (
												<TableRow key={row.id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{row.id}
													</TableCell>
													<TableCell align="left">{row.name}</TableCell>
													<TableCell align="center">
														{row.abreviatura}
														{isAccess(access, 'CONFIG UPDATE') ? (
															<Tooltip
																title={
																	row.abreviatura === ''
																		? 'Agregar'
																		: 'Editar'
																}
															>
																<IconButton
																	size="small"
																	style={{
																		marginLeft: 5,
																		color:
																			row.abreviatura === ''
																				? '#2979ff'
																				: '#ff9800'
																	}}
																	onClick={() => {
																		setSelectedMun(row);
																		setShowModal(true);
																	}}
																>
																	<FontAwesomeIcon
																		icon={
																			row.abreviatura === ''
																				? faCirclePlus
																				: faPenToSquare
																		}
																	/>
																</IconButton>
															</Tooltip>
														) : (
															<></>
														)}
													</TableCell>
													<TableCell align="center">
														{row.communities.length}
													</TableCell>
													<TableCell align="center">
														<IconButton
															style={{ color: '#2979ff' }}
															onClick={() => {
																navigate(
																	`${ruta}/locations/municipalities/${row.id}`
																);
															}}
														>
															<FontAwesomeIcon
																icon={faExternalLink}
															/>
														</IconButton>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>
							<PaginationComponent
								data={municipalityList}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>

			<SmallModal
				titulo={
					selectedMun.abreviatura === '' ? 'Agregar Abreviatura' : 'Editar Abreviatura'
				}
				isVisible={showModal}
				setVisible={setShowModal}
			>
				<AddEditAbMun selectedMun={selectedMun} setShowModal={setShowModal} />
			</SmallModal>
		</div>
	);
};

export default Municipalities;

import React, { useState, useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import {
	Button,
	IconButton,
	Container,
	Paper,
	Stack,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	TextField,
	InputAdornment
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCirclePlus, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { DataContext } from '../../../../context/DataContext';
import { getRuta, isAccess, simpleMessage } from '../../../../helpers/Helpers';
import { getToken } from '../../../../api/Account';
import NoData from '../../../../components/NoData';
import { PaginationComponent } from '../../../../components/PaginationComponent';

import { getArqueosCajaListAsync } from '../../../../api/ArqueoCaja';
import moment from 'moment';

const CashregisterList = () => {
	const [arqueoList, setArquoList] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	// const [localReload, setLocalReload] = useState(false);

	var date = new Date();
	const [fechaDesde, setFechaDesde] = useState(new Date(date.getFullYear(), date.getMonth(), 1));

	const { setIsLoading, setIsUnautorized, access, selectedSucursal, setSelectedSucursal } =
		useContext(DataContext);

	let ruta = getRuta();
	const token = getToken();
	let navigate = useNavigate();

	useEffect(() => {
		getArqueoCaja(fechaDesde);
	}, []);

	const withSearch = arqueoList.filter((val) => {
		if (searchTerm === '') {
			return val;
		} else if (val.gestor.includes(searchTerm)) {
			return val;
		}
	});

	const onChangeSearch = (val) => {
		setCurrentPage(1);
		setSearchTerm(val);
		paginate(1);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [itemsperPage] = useState(10);
	const indexLast = currentPage * itemsperPage;
	const indexFirst = indexLast - itemsperPage;
	const currentItem = arqueoList.slice(indexFirst, indexLast);
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const getArqueoCaja = async (desde) => {
		setIsLoading(true);
		const result = await getArqueosCajaListAsync(token, moment(desde).format('YYYY-MM-DD'));
		if (!result.statusResponse) {
			setIsLoading(false);
			if (result.error === 'Unauthorized') {
				setIsUnautorized(true);
				return;
			}
			simpleMessage(result.error, 'error');
			return;
		}
		setIsLoading(false);

		setArquoList(result.data.result);
	};

	const onChangeFecha = (desde) => {
		setFechaDesde(desde);
		getArqueoCaja(desde);
	};

	return (
		<div>
			{' '}
			<Container maxWidth="xl">
				<Paper
					style={{
						padding: 20,
						textAlign: 'center'
					}}
				>
					<Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
						<Typography color={'#00a152'} variant="h4" fontWeight={'bold'}>
							Lista de Arqueos
						</Typography>

						{isAccess(access, 'CAJA CREATE') ? (
							<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
								<DesktopDatePicker
									closeOnSelect
									label="Desde"
									inputFormat="DD/MM/YYYY"
									value={fechaDesde}
									onChange={(newValue) => onChangeFecha(newValue)}
									renderInput={(params) => <TextField {...params} />}
								/>

								<Button
									onClick={() => {
										navigate(`${ruta}/cashregisteradd`);
									}}
									startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
									variant="outlined"
									style={{
										color: '#00a152',
										borderColor: '#00a152'
									}}
								>
									Agregar Arqueo
								</Button>
							</Stack>
						) : (
							<></>
						)}
					</Stack>
					<hr />

					<TextField
						style={{ marginTop: 20, marginBottom: 20, maxWidth: 600 }}
						fullWidth
						onChange={(e) => onChangeSearch(e.target.value.toUpperCase())}
						value={searchTerm}
						label={'Buscar Arqueo'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton>
										<FontAwesomeIcon
											icon={faSearch}
											style={{ color: '#1769aa' }}
										/>
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
					{isEmpty(currentItem) ? (
						<NoData />
					) : (
						<Stack spacing={2}>
							<TableContainer>
								<Table aria-label="a dense table" size="small">
									<TableHead>
										<TableRow>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												#
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Fecha
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												S.Inicial
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												T.Ingresos
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												T.Egresos
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												S.Final
											</TableCell>
											<TableCell
												align="center"
												className="text-primary fw-bold"
											>
												Diferencia
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Observaciones
											</TableCell>
											<TableCell
												align="left"
												className="text-primary fw-bold"
											>
												Recibido
											</TableCell>

											{/* {isAccess(access, 'CAJA VER') ? (
												<TableCell
													align="center"
													className="text-primary fw-bold"
												>
													Ver
												</TableCell>
											) : (
												<></>
											)} */}
										</TableRow>
									</TableHead>

									<TableBody>
										{currentItem.map((row) => {
											const {
												diferencia,
												fechaCreacion,
												recibidoPor,
												totalEfectivo,
												observaciones,
												saldoFinal,
												totalEgresos,
												ingresoTotal,
												saldoInicial,
												id
											} = row;

											return (
												<TableRow key={id}>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{id}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{moment(fechaCreacion).format('L')}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoInicial)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(ingresoTotal)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(totalEgresos)}
													</TableCell>
													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(saldoFinal)}
													</TableCell>

													<TableCell
														align="center"
														component="th"
														scope="row"
													>
														{new Intl.NumberFormat('es-NI', {
															style: 'currency',
															currency: 'NIO'
														}).format(diferencia)}
													</TableCell>
													<TableCell
														align="left"
														component="th"
														scope="row"
													>
														{observaciones}
													</TableCell>
													<TableCell
														align="left"
														component="th"
														scope="row"
													>
														{recibidoPor}
													</TableCell>
													{/* {isAccess(access, 'CAJA VER') ? (
														<TableCell
															align="center"
															className="text-primary fw-bold"
														>
															<IconButton
																style={{
																	color: '#ffc400'
																}}
																onClick={() => {
																	navigate(
																		`${ruta}/arqueocaja/${row.id}`
																	);
																}}
															>
																<FontAwesomeIcon
																	icon={faExternalLink}
																/>
															</IconButton>
														</TableCell>
													) : (
														<></>
													)} */}
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
							</TableContainer>

							<PaginationComponent
								data={withSearch}
								paginate={paginate}
								itemsperPage={itemsperPage}
							/>
						</Stack>
					)}
				</Paper>
			</Container>
		</div>
	);
};

export default CashregisterList;

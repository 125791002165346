import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Container,
  Stack,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  IconButton,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Divider,
} from "@mui/material";
import { isEmpty } from "lodash";

import { useParams } from "react-router-dom";
import { DataContext } from "../../../context/DataContext";

import { getToken, getUser } from "../../../api/Account";
import ReactToPrint from "react-to-print";
import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import NoData from "../../../components/NoData";
import { getDefeatedCreditsAsync } from "../../../api/Report";
import { PrintReport } from "../../../components/PrintReport";
import moment from "moment";
import { getGestoresAsync } from "../../../api/Users";
import { simpleMessage } from "../../../helpers/Helpers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx/xlsx.mjs";

const DefeatedCredits = () => {
  const [data, setData] = useState([]);

  const compRef = useRef();
  const { selectedGestor, selectedSortBy } = useParams();

  const { setIsLoading, setIsUnautorized, title, slogan, icon } =
    useContext(DataContext);

  const [gestorName, setGestorName] = useState("");
  const [gestorList, setGestorList] = useState([]);

  const token = getToken();
  const user = getUser();

  useEffect(() => {
    (async () => {
      let gestores = await getGestores();

      if (selectedGestor === "t") {
        setGestorName("Todos");
      } else {
        let name = gestores.filter(
          (item) => item.id.toString() === selectedGestor
        );

        setGestorName(name[0].fullName);
      }

      const data = {
        selectedSortBy,
        gestorId: selectedGestor === "t" ? 0 : selectedGestor,
      };

      setIsLoading(true);
      const result = await getDefeatedCreditsAsync(token, data);
      if (!result.statusResponse) {
        setIsLoading(false);
        if (result.error === "Unauthorized") {
          setIsUnautorized(true);
          return;
        }
        simpleMessage(result.error, "error");
        return;
      }
      setIsLoading(false);
      setData(result.data.result);
    })();
  }, []);

  const getGestores = async () => {
    setIsLoading(true);
    const result = await getGestoresAsync(token);
    if (!result.statusResponse) {
      setIsLoading(false);
      if (result.error === "Unauthorized") {
        setIsUnautorized(true);
        return;
      }
      simpleMessage(result.error, "error");
      return;
    }

    setIsLoading(false);

    setGestorList(
      selectedGestor === "t"
        ? result.data.result
        : result.data.result.filter((g) => g.id.toString() === selectedGestor)
    );

    return result.data.result;
  };

  const pageStyle = `
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }  
  @page { size: landscape;  margin: 8mm; }
`;

  let totalAtrasoConsol = 0;
  let totalCapitalConsol = 0;
  let totalSaldoConsol = 0;

  const exportExcel = () => {
    let exp = [];
    data.map((item) => {
      const dataGestor = gestorList.filter(
        (gestor) => gestor.id === item.gestor
      );
      let gestor = dataGestor[0];
      let obj = {
        idCredito: item.id,
        fechaEntrega: moment(item.fechaEntrega).format("L"),
        cliente: item.fullName,
        montoEntregado: item.montoEntregado,
        fechaVencimiento: moment(item.fechaVencimiento).format("L"),
        diasAtraso: item.atrasoDias,
        saldoTotal: item.saldoTotal,
        gestor: gestor.fullName,
      };
      exp.push(obj);
    });

    var bk = XLSX.utils.book_new(),
      bs = XLSX.utils.json_to_sheet(exp);

    XLSX.utils.book_append_sheet(bk, bs, "creditosVencidos");
    XLSX.writeFile(bk, "CreditosVencidos.xlsx");
  };

  return (
    <div>
      <Dialog fullScreen open={true}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
            <Typography
              sx={{ ml: 2, flex: 1, textAlign: "center" }}
              variant="h5"
              component="div"
            >
              {`${title} `}
            </Typography>
            <img loading="lazy" src={icon} alt="logo" style={{ height: 40 }} />
          </Toolbar>
        </AppBar>

        <Stack display="flex" justifyContent="center">
          <Stack
            direction={"row"}
            justifyContent="space-between"
            paddingLeft={5}
            paddingRight={5}
            marginTop={1}
            marginBottom={1}
          >
            <IconButton variant="outlined" onClick={() => exportExcel()}>
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                style={{ fontSize: 40, color: "#00a152", width: 40 }}
              />
            </IconButton>

            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {slogan}
              </Typography>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                variant="h6"
                component="div"
              >
                Reporte de Créditos Vencidos
              </Typography>
            </Stack>

            <ReactToPrint
              trigger={() => {
                return (
                  <IconButton
                    variant="outlined"
                    // style={{ position: "fixed", right: 20, top: 75 }}
                  >
                    <PrintRoundedIcon
                      style={{ fontSize: 40, color: "#2979ff", width: 40 }}
                    />
                  </IconButton>
                );
              }}
              content={() => compRef.current}
              pageStyle={pageStyle}
            />
          </Stack>

          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Ordenados por: ${
                    parseInt(selectedSortBy) === 0 ? "Nombre" : "Días de Atraso"
                  }`}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>
          </Container>
        </Stack>

        <Container maxWidth={false} sx={{ textAlign: "center" }}>
          <hr />
          {isEmpty(data) ? (
            <NoData />
          ) : (
            <>
              {gestorList.map((gestor) => {
                const cGestor = data.filter((c) => c.gestor === gestor.id);
                let totalCapital = 0;
                let totalAtraso = 0;
                let totalSaldo = 0;

                cGestor.map((c) => {
                  totalCapital += c.montoEntregado;
                  totalAtraso += c.atraso;
                  totalSaldo += c.saldoTotal;
                });

                cGestor.sort(function (a, b) {
                  if (selectedSortBy === "0") {
                    if (a.fullName > b.fullName) {
                      return 1;
                    }
                    if (a.fullName < b.fullName) {
                      return -1;
                    }
                    return 0;
                  } else {
                    if (a.atrasoDias > b.atrasoDias) {
                      return 1;
                    }
                    if (a.atrasoDias < b.atrasoDias) {
                      return -1;
                    }
                    return 0;
                  }
                });

                totalAtrasoConsol += totalAtraso;
                totalCapitalConsol += totalCapital;
                totalSaldoConsol += totalCapital;
                return (
                  <Stack key={gestor.id}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        sx={{
                          color: "#2196f3",
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        Gestor:
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "left",
                        }}
                        fontSize={11}
                      >
                        {gestor.fullName}
                      </Typography>
                    </Stack>

                    <Divider />

                    {isEmpty(cGestor) ? (
                      <>
                        <Typography
                          sx={{
                            color: "#2196f3",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                          fontSize={15}
                        >
                          Gestor no tiene créditos asignados!
                        </Typography>
                        <hr />
                      </>
                    ) : (
                      <>
                        <TableContainer>
                          <Table aria-label="simple table" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  #
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  F.Entrega
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="text-primary fw-bold"
                                >
                                  Nombre Completo
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  Monto Entregado
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  F.Vencimiento
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  Días Atraso
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="text-primary fw-bold"
                                >
                                  Saldo Total
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {cGestor.map((row) => {
                                return (
                                  <TableRow key={row.id}>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                      style={{ fontSize: 11 }}
                                    >
                                      {row.id}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: 11 }}
                                    >
                                      {moment(row.fechaEntrega).format("L")}
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      style={{ fontSize: 11 }}
                                    >
                                      {row.fullName}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: 11 }}
                                    >
                                      {new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(row.montoEntregado)}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: 11 }}
                                    >
                                      {moment(row.fechaVencimiento).format("L")}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: 11 }}
                                    >
                                      {row.atrasoDias}
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: 11 }}
                                    >
                                      {new Intl.NumberFormat("es-NI", {
                                        style: "currency",
                                        currency: "NIO",
                                      }).format(row.atraso)}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <hr />
                        <Stack
                          direction="row"
                          flex="row"
                          justifyContent="space-around"
                        >
                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total Créditos
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {cGestor.length}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total Capital
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalCapital)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Total Atraso.
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalAtraso)}
                            </Typography>
                          </Stack>

                          <Stack textAlign="center">
                            <Typography
                              style={{
                                fontWeight: "bold",
                                color: "#03a9f4",
                                fontSize: 11,
                              }}
                            >
                              Saldo Total
                            </Typography>
                            <Typography style={{ fontSize: 11 }}>
                              {new Intl.NumberFormat("es-NI", {
                                style: "currency",
                                currency: "NIO",
                              }).format(totalSaldo)}
                            </Typography>
                          </Stack>
                        </Stack>
                        <hr />
                      </>
                    )}
                  </Stack>
                );
              })}
            </>
          )}

          {gestorList.length === 1 ? (
            <></>
          ) : (
            <Stack>
              <Typography
                sx={{
                  color: "#2196f3",
                  textAlign: "center",
                }}
              >
                Consolidado
              </Typography>

              <hr />

              <Stack direction="row" flex="row" justifyContent="space-around">
                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Créditos
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {data.length}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Capital
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalCapitalConsol)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Atraso
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalAtrasoConsol)}
                  </Typography>
                </Stack>

                <Stack textAlign="center">
                  <Typography
                    style={{
                      fontWeight: "bold",
                      color: "#03a9f4",
                      fontSize: 11,
                    }}
                  >
                    Total Saldo
                  </Typography>
                  <Typography style={{ fontSize: 11 }}>
                    {new Intl.NumberFormat("es-NI", {
                      style: "currency",
                      currency: "NIO",
                    }).format(totalSaldoConsol)}
                  </Typography>
                </Stack>
              </Stack>
              <hr />
            </Stack>
          )}
        </Container>
      </Dialog>

      <div
        style={{
          display: "none",
        }}
      >
        <PrintReport
          ref={compRef}
          titulo={"Reporte de Créditos Vencidos"}
          fecha={`Fecha: ${moment().format("L")}`}
        >
          <Container maxWidth={false} sx={{ textAlign: "center" }}>
            <Stack direction={"row"} justifyContent="space-between">
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Parametros:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Gestor Seleccionado: ${
                    selectedGestor === "t" ? "Todos" : gestorName
                  }`}
                </Typography>

                <Typography fontSize={11} textAlign="left">
                  {`Ordenados por: ${
                    parseInt(selectedSortBy) === 0 ? "Nombre" : "Días de Atraso"
                  }`}
                </Typography>
              </Stack>
              <Stack>
                <Typography
                  sx={{
                    color: "#2196f3",
                    textAlign: "left",
                  }}
                  fontSize={11}
                >
                  Usuario:
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {user}
                </Typography>
                <Typography fontSize={11} textAlign="left">
                  {`Fecha y Hora: ${moment().format("DD/MM/YYYY hh:mm A")}`}
                </Typography>
              </Stack>
            </Stack>

            <Container maxWidth={false} sx={{ textAlign: "center" }}>
              <hr />
              {isEmpty(data) ? (
                <NoData />
              ) : (
                <>
                  {gestorList.map((gestor) => {
                    const cGestor = data.filter((c) => c.gestor === gestor.id);
                    let totalCapital = 0;
                    let totalAtraso = 0;
                    let totalSaldo = 0;

                    cGestor.map((c) => {
                      totalCapital += c.montoEntregado;
                      totalAtraso += c.atraso;
                      totalSaldo += c.saldoTotal;
                    });

                    return (
                      <Stack key={gestor.id}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            sx={{
                              color: "#2196f3",
                              textAlign: "left",
                            }}
                            fontSize={11}
                          >
                            Gestor:
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "left",
                            }}
                            fontSize={11}
                          >
                            {gestor.fullName}
                          </Typography>
                        </Stack>

                        <Divider />

                        {isEmpty(cGestor) ? (
                          <>
                            <Typography
                              sx={{
                                color: "#2196f3",
                                textAlign: "center",
                                fontWeight: "bold",
                              }}
                              fontSize={15}
                            >
                              Gestor no tiene créditos asignados!
                            </Typography>
                            <hr />
                          </>
                        ) : (
                          <>
                            <TableContainer>
                              <Table aria-label="simple table" size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      #
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      F.Entrega
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      Nombre Completo
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      Monto Entregado
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      F.Vencimiento
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      Días de Atraso
                                    </TableCell>

                                    <TableCell
                                      align="center"
                                      className="text-primary fw-bold"
                                      style={{ fontSize: 11 }}
                                    >
                                      Saldo Total
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {cGestor.map((row) => {
                                    return (
                                      <TableRow key={row.id}>
                                        <TableCell
                                          align="center"
                                          component="th"
                                          scope="row"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {row.id}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {moment(row.fechaEntrega).format("L")}
                                        </TableCell>

                                        <TableCell
                                          align="left"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {row.fullName}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.montoEntregado)}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {moment(row.fechaVencimiento).format(
                                            "L"
                                          )}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {row.atrasoDias}
                                        </TableCell>

                                        <TableCell
                                          align="center"
                                          style={{ fontSize: 9 }}
                                          className="text-dark"
                                        >
                                          {new Intl.NumberFormat("es-NI", {
                                            style: "currency",
                                            currency: "NIO",
                                          }).format(row.atraso)}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>

                            <hr />
                            <Stack
                              direction="row"
                              flex="row"
                              justifyContent="space-around"
                            >
                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Créditos
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {cGestor.length}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Capital
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalCapital)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Total Atraso.
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalAtraso)}
                                </Typography>
                              </Stack>

                              <Stack textAlign="center">
                                <Typography
                                  style={{
                                    fontWeight: "bold",
                                    color: "#03a9f4",
                                    fontSize: 11,
                                  }}
                                >
                                  Saldo Total
                                </Typography>
                                <Typography style={{ fontSize: 11 }}>
                                  {new Intl.NumberFormat("es-NI", {
                                    style: "currency",
                                    currency: "NIO",
                                  }).format(totalSaldo)}
                                </Typography>
                              </Stack>
                            </Stack>
                            <hr />
                          </>
                        )}
                      </Stack>
                    );
                  })}
                </>
              )}

              {gestorList.length === 1 ? (
                <></>
              ) : (
                <Stack>
                  <Typography
                    sx={{
                      color: "#2196f3",
                      textAlign: "center",
                    }}
                  >
                    Consolidado
                  </Typography>

                  <hr />

                  <Stack
                    direction="row"
                    flex="row"
                    justifyContent="space-around"
                  >
                    <Stack textAlign="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#03a9f4",
                          fontSize: 11,
                        }}
                      >
                        Total Créditos
                      </Typography>
                      <Typography style={{ fontSize: 11 }}>
                        {data.length}
                      </Typography>
                    </Stack>

                    <Stack textAlign="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#03a9f4",
                          fontSize: 11,
                        }}
                      >
                        Total Capital
                      </Typography>
                      <Typography style={{ fontSize: 11 }}>
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(totalCapitalConsol)}
                      </Typography>
                    </Stack>

                    <Stack textAlign="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#03a9f4",
                          fontSize: 11,
                        }}
                      >
                        Total Atraso
                      </Typography>
                      <Typography style={{ fontSize: 11 }}>
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(totalAtrasoConsol)}
                      </Typography>
                    </Stack>

                    <Stack textAlign="center">
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#03a9f4",
                          fontSize: 11,
                        }}
                      >
                        Total Saldo
                      </Typography>
                      <Typography style={{ fontSize: 11 }}>
                        {new Intl.NumberFormat("es-NI", {
                          style: "currency",
                          currency: "NIO",
                        }).format(totalSaldoConsol)}
                      </Typography>
                    </Stack>
                  </Stack>
                  <hr />
                </Stack>
              )}
            </Container>
          </Container>
        </PrintReport>
      </div>
    </div>
  );
};

export default DefeatedCredits;

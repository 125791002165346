import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Container,
  Button,
  Stack,
} from "@mui/material";
import { getRuta } from "../../../../helpers/Helpers";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SelectorClientsGestor = () => {
  const [selectedStatus, setSelectedStatus] = useState(2);
  const [selectedSortBy, setSelectedSortBy] = useState(0);

  let ruta = getRuta();

  const verReport = () => {
    var params = `${selectedStatus}/${selectedSortBy}`;
    window.open(`${ruta}/r-clientsgestor/${params}`);
  };

  return (
    <div>
      <Container style={{ width: 550 }}>
        <Paper
          elevation={10}
          style={{
            padding: 20,
          }}
        >
          <Stack spacing={2}>
            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Estado del Cliente
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setSelectedStatus(e.target.value)}
                value={selectedStatus}
                label=" Estado del Cliente"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione una Opción</em>
                </MenuItem>

                <MenuItem key={0} value={0}>
                  ACTIVOS
                </MenuItem>
                <MenuItem key={1} value={1}>
                  INACTIVOS
                </MenuItem>
                <MenuItem key={2} value={2}>
                  TODOS...
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ textAlign: "left" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Ordenados por
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={(e) => setSelectedSortBy(e.target.value)}
                value={selectedSortBy}
                label="Ordenados por"
                style={{ textAlign: "left" }}
              >
                <MenuItem value="">
                  <em>Seleccione una Opción</em>
                </MenuItem>

                <MenuItem key={0} value={0}>
                  NOMBRE
                </MenuItem>
                <MenuItem key={1} value={1}>
                  PROMEDIO
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              startIcon={
                <FontAwesomeIcon icon={faPrint} style={{ marginRight: 20 }} />
              }
              fullWidth
              onClick={() => verReport()}
              style={{
                color: "#4caf50",
                borderColor: "#4caf50",
              }}
              size="large"
            >
              Generar Reporte
            </Button>
          </Stack>
        </Paper>
      </Container>
    </div>
  );
};

export default SelectorClientsGestor;
